import React, { Component } from "react";
import includes from "array-includes";
import { connect } from "react-redux";
import {
  NavLink,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap";
import filterAliases from "./filterAliases";
import filterBlacklist from "./filterBlacklist";
import { campaignActions } from "../../../campaigns";
import "./filter.css";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.toggleID = `${props.name}-filter-toggle`;
    this.state = {
      filterOptions: this.props.options,
      isDropdownOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filterOptions: nextProps.options,
      isDropdownOpen: false
    });
  }

  toggleDrop = ev => {
    const noToggle = ev.target.dataset.notoggle;
    if (!noToggle) {
      this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    }
  };

  updateFilter = (option, value) => {
    const { filterOptions } = this.state;
    const { setCampaignFilter, name } = this.props;
    filterOptions[option].active =
      typeof value === "undefined" ? !filterOptions[option].active : value;
    this.setState({ filterOptions });
    setCampaignFilter({ name, filter: filterOptions[option], option });

    // Reload campaigns since filters have changed
    this.props.loadCampaigns();
  };
  onCheckbox = optionName => {
    const self = this;
    return event => {
      self.updateFilter(optionName, event.target.checked);
    };
  };
  resetFilters = () => {
    const { filterOptions } = this.state;
    Object.keys(filterOptions).forEach(option => {
      this.updateFilter(option, false);
    });

    // Reload campaigns since filters have been reset
    this.props.loadCampaigns();
  };
  renderOptions() {
    const { name } = this.props;
    const { filterOptions } = this.state;
    const filterOptionNames = Object.keys(filterOptions);
    const optionAliasData = filterAliases[name];
    const alias =
      typeof optionAliasData !== "undefined" ? optionAliasData.alias : name;
    const menuItems = filterOptionNames.map(optionKey => {
      const optionName = filterOptions[optionKey].name;
      if (
        typeof filterBlacklist[name] !== "undefined" &&
        includes(filterBlacklist[name], optionName)
      ) {
        return null;
      }
      const state = filterOptions[optionKey].active;
      const metric =
        typeof optionAliasData !== "undefined" ? optionAliasData.metric : "";

      return (
        <DropdownItem key={`${name}${optionName}`} data-notoggle>
          <div
            className="custom-control custom-checkbox mt-2"
            key={`${name}${optionName}-wrapper`}
            data-notoggle
            onClick={this.onCheckbox(optionKey)}
          >
            <input
              type="checkbox"
              checked={state}
              onChange={this.onCheckbox(optionKey)}
              data-notoggle
              className="custom-control-input"
            />
            <label
              className="custom-control-label filter__capital"
              data-notoggle
            >
              {`${optionName} ${metric}`}
            </label>
          </div>
        </DropdownItem>
      );
    });
    menuItems.unshift(
      <DropdownItem
        key={`${name}-filter-header`}
        header
        data-notoggle
      >{`Filter ${alias}`}</DropdownItem>
    );
    menuItems.push(<DropdownItem key={`${name}-filter-divider`} divider />);
    menuItems.push(
      <DropdownItem
        key={`${name}-filter-reset`}
        onClick={this.resetFilters}
        className="small"
        data-notoggle
      >{`Reset filter`}</DropdownItem>
    );
    return menuItems;
  }
  render() {
    const { name } = this.props;
    const { filterOptions } = this.state;
    const filterOptionNames = Object.keys(filterOptions);
    if (includes(filterOptionNames, "")) {
      return null;
    }
    if (filterOptionNames.length === 0) {
      return null;
    }
    const optionAliasData = filterAliases[name];
    const alias =
      typeof optionAliasData !== "undefined" ? optionAliasData.alias : name;
    return (
      <Dropdown isOpen={this.state.isDropdownOpen} toggle={this.toggleDrop}>
        <DropdownToggle nav>
          <NavLink
            className="filter__capital filter__expander"
            id={this.toggleID}
          >
            {alias}
          </NavLink>
        </DropdownToggle>
        <DropdownMenu
          className="animated fadeInUp tease header__dropdown-menu"
          right
        >
          {this.renderOptions()}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapDispatchToProps = {
  setCampaignFilter: campaignActions.setFilter,
  loadCampaigns: campaignActions.loadCampaigns
};
export default connect(
  null,
  mapDispatchToProps
)(Filter);
