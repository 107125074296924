import { Record } from "immutable";

export const Campaign = new Record({
  campaign_id: null,
  industry: null,
  location: null,
  role: null,
  status: null,
  title: null,
  reach: new Record({
    candidates: null,
    likes: null,
    shares: null,
    views: null
  }),
  leads: null
});
