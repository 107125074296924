import {
  SET_AD_UPDATE_COMPLETED,
  RESET_AD_UPDATE_COMPLETED
} from "./actions-types";

const NotificationState = {
  adUpdateCompleted: false
};

export const notificationReducer = (state = NotificationState, { type }) => {
  switch (type) {
    case SET_AD_UPDATE_COMPLETED:
      return { adUpdateCompleted: true };

    case RESET_AD_UPDATE_COMPLETED:
      return { adUpdateCompleted: false };
    default:
      return state;
  }
};
