import { LOAD_ORG_SUCCESS } from "./action-types";
import { tokenUtils, authActions } from "../auth";

export function loadOrg(orgID) {
  return (dispatch, getState) => {
    tokenUtils
      .makeRequest(`/organizations/${orgID}`)
      .then(orgData => {
        dispatch(loadOrgSuccess(orgData));
      })
      .catch(err => {
        console.warn(`Signing out due to organization API rejection.`);
        authActions.signOut()(dispatch);
      });
  };
}

export function loadOrgSuccess(payload) {
  return {
    type: LOAD_ORG_SUCCESS,
    payload: payload
  };
}
