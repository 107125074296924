import { stringify } from "query-string";
import { firebaseAuth } from "../firebase";

const API_BASE_URL =
  process.env.REACT_APP_LOCAL_SERVER === "true"
    ? `http://localhost:8000/api`
    : `/api`;
const VERIFY_URI = "/login/user";

const doFetch = (request, raw, direct) => {
  return fetch(request).then(response => {
    if (!direct && (response.status < 200 || response.status >= 300)) {
      return Promise.reject(null);
    }

    if (direct && response.status >= 400) {
      return response
        .json()
        .then(errorPayload => Promise.reject(new Error(errorPayload.error)));
    }

    return raw ? response : response.json();
  });
};

export const uploadFormData = (
  apiURL,
  options = null,
  raw = false,
  method = "GET",
  direct = false
) => {
  let uri = API_BASE_URL + apiURL;

  return firebaseAuth.currentUser.getIdToken().then(authToken => {
    const request = new Request(uri, {
      method,
      body: options,
      headers: new Headers({
        auth_token: authToken
      })
    });

    return doFetch(request, raw, direct);
  });
};

export const makeRequest = (
  apiURL,
  options = null,
  raw = false,
  method = "GET",
  direct = false
) => {
  let uri = API_BASE_URL + apiURL;
  if (options) {
    uri += "?" + stringify(options);
  }

  return firebaseAuth.currentUser.getIdToken().then(authToken => {
    const request = new Request(uri, {
      method,
      headers: new Headers({
        "Content-Type": "application/json",
        auth_token: authToken
      })
    });

    return doFetch(request, raw, direct);
  });
};

export const tokenTest = () => {
  return makeRequest(VERIFY_URI);
};
