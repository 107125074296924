import React, { Component } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

class CampaignShare extends Component {
  renderShareLink(source, link) {
    return (
      <CopyToClipboard text={link}>
        <button className="btn btn-block btn-sm btn-outline-secondary p-2 px-3 d-flex flex-row align-items-center text-left">
          <div className="pr-3">
            <ion-icon
              name={`logo-${source}`}
              style={{ marginLeft: "14.4px" }}
            />
          </div>
          <div>
            <strong>{link}</strong>
            <br />
            <small>Click to copy link</small>
          </div>
        </button>
      </CopyToClipboard>
    );
  }
  render() {
    const { campaignID } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData || !campaignData.reach) return null;

    const posts = [];

    [
      ...(campaignData.reach.posts || []),
      ...(campaignData.reach.instagramPosts || []),
      ...(campaignData.reach.linkedInPosts || [])
    ].forEach(post => {
      if (!post.short_url) return;
      posts.push({ ...post });
    });

    if (!posts.length) return null;

    return (
      <div className="col-12">
        <p className="text-op-75 mb-5">
          Don't forget to share your ads in social media, the links are
          shareable.
        </p>

        {posts.map(shareLinkData =>
          this.renderShareLink(
            shareLinkData.source,
            `${window.location.hostname}/s/${shareLinkData.short_url}`
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaigns.list
});

export default connect(mapStateToProps)(CampaignShare);
