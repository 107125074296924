import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";

import { campaignActions } from "../../../campaigns";
import Listing from "../../components/listing";
import Menu from "../menu";
import CustomAdRequest from "../custom-ad-request";
import EditAd from "../edit";
import { adDataActions } from "../../../adData";
import NotificationBar from "../notification-bar";
// After what point to trigger extra campaign loding
const SCROLL_PROGRESS_TRIGGER_FOR_LOADING = 0.8;

class CampaignDisplay extends Component {
  componentWillMount() {
    this.props.loadCampaigns();
    this.props.loadCampaignFilters();
  }
  componentWillReceiveProps(nextProps) {
    const { campaigns, user } = nextProps;
    if (user) {
      if (!campaigns) {
        this.props.loadCampaigns();
        this.props.loadCampaignFilters();
      } else if (
        campaigns.get("status") !== "loaded" &&
        campaigns.get("status") !== "loading"
      ) {
        this.props.loadCampaigns();
        this.props.loadCampaignFilters();
      } else if (this.props.user) {
        // User and/or organziation switch. Reload.
        const nextOrg = user.get("orgData").get("current");
        const currentOrg = this.props.user.get("orgData").get("current");
        if (nextOrg !== currentOrg) {
          this.props.loadCampaigns();
          this.props.loadCampaignFilters();
        }
      }
    }
  }

  // Handle infinite scroll
  scrollHandler = e => {
    const { loadCampaigns, campaigns } = this.props;

    const target = e.target;
    const maxPos = target.scrollHeight - target.clientHeight;
    const currPos = target.scrollTop;

    // Calculate the scroll progress
    // Once it passes a threshold, load additonal campaigns
    if (
      currPos / maxPos > SCROLL_PROGRESS_TRIGGER_FOR_LOADING &&
      campaigns.status !== "loading" // Don't load if loading is in progress
    ) {
      // Check if we have fetched everything already
      if (campaigns.campaignCount > campaigns.list.length) {
        // Load remaining campaigns
        loadCampaigns(false);
      }
    }
  };

  render() {
    const { campaigns, user } = this.props;
    const { campaignType } = this.props.match.params;
    let canEditAds = false;
    let isAdmin = false;

    const typeFilter = campaignType === "jobs" ? "job" : campaignType;

    if (user) {
      canEditAds = user.get("canEditAds");
      isAdmin = user.get("isAdmin");
    }

    return (
      <>
        <div
          className="col-7 bg-white p-5 scroll-y"
          style={{ maxHeight: "100%", height: "100%" }}
          onScroll={this.scrollHandler}
        >
          <NotificationBar />
          <Switch>
            {(isAdmin || canEditAds) && (
              <Route exact path="/:campaignType/:campaignID/edit">
                <EditAd type="campaign" campaigns={campaigns.list} />
              </Route>
            )}
            <Route path="/:campaignType/">
              <>
                <Menu />
                <CustomAdRequest
                  type={campaignType}
                  isFirst={
                    !Object.values(campaigns.list || {}).filter(
                      campaignData => campaignData.type === typeFilter
                    ).length
                  }
                />
                <Listing
                  list={campaigns.list}
                  isLoading={campaigns.status === "loading"}
                />
              </>
            </Route>
          </Switch>
        </div>
        <NotificationBar />
      </>
    );
  }
}

const mapStateToProps = state => ({
  adData: state.adData,
  campaigns: state.campaigns,
  user: state.user
});

const mapDispatchToProps = {
  loadCampaigns: campaignActions.loadCampaigns,
  loadCampaignFilters: campaignActions.loadCampaignFilters,
  setAdData: adDataActions.setAdData
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDisplay);
