import { Record } from "immutable";
import {
  LOAD_CAMPAIGNS_SUCCESS,
  LOAD_CAMPAIGN_FILTERS_SUCCESS,
  UNLOAD_CAMPAIGNS_SUCCESS,
  SET_FILTER,
  LOAD_CAMPAIGNS_STATUS,
  SET_SEARCH,
  SET_TYPE_FILTER,
  RESET_FILTER_AND_SEARCH,
  RESET_CAMPAIGNS
} from "./actions-types";
import { SIGN_OUT_SUCCESS } from "src/auth/action-types";

const CampaignsState = new Record({
  filter: null,
  list: null,
  campaignCount: null,
  status: null,
  search: {
    query: null
  },
  availableFilters: null,
  typeFilter: "job"
});

const initFilters = (availableFilters, state) => {
  const filterOptions = { category: {}, location: {}, status: {} };

  const setFilters = (filterName, options) => {
    options.forEach(option => {
      filterOptions[filterName][option.toLowerCase()] = {
        name: option,
        active: false
      };
    });
  };

  setFilters("category", availableFilters.categories);
  setFilters("location", availableFilters.locations);
  setFilters("status", availableFilters.statuses);

  return state
    .set("filter", filterOptions)
    .set("availableFilters", availableFilters);
};

const initSearch = state => {
  const search = {
    query: null
  };

  return state.set("search", search);
};

const initCampaigns = (newCampaignList, campaignCount, fullLoad, state) => {
  const loadedState = state
    .set("status", "loaded")
    .set("campaignCount", campaignCount);

  // If doing a partial load, append new campaigns to the list instead
  if (!fullLoad) {
    const currentCampaignList = loadedState.get("list");
    currentCampaignList.push(...newCampaignList);

    return loadedState.set("list", currentCampaignList);
  }

  return loadedState.set("list", newCampaignList);
};

export const campaignsReducer = (
  state = new CampaignsState(),
  { type, payload }
) => {
  switch (type) {
    case LOAD_CAMPAIGNS_SUCCESS:
      const { list, campaignCount, fullLoad } = payload;
      return initCampaigns(list, campaignCount, fullLoad, state);

    case SET_FILTER:
      const filter = state.get("filter");
      filter[payload.name][payload.option] = payload.filter;
      return state.set("filter", Object.assign({}, filter));
    case LOAD_CAMPAIGNS_STATUS:
      return state.set("status", payload);
    case SET_SEARCH:
      const oldSearch = state.search;
      oldSearch.query = payload;
      return state.set("search", Object.assign({}, oldSearch));
    case SET_TYPE_FILTER:
      return state.set("typeFilter", payload);
    case RESET_FILTER_AND_SEARCH:
      const searchInitState = initSearch(state);
      return initFilters(state.get("availableFilters"), searchInitState);
    case LOAD_CAMPAIGN_FILTERS_SUCCESS:
      return initFilters(payload, state);
    case RESET_CAMPAIGNS:
    case UNLOAD_CAMPAIGNS_SUCCESS:
    case SIGN_OUT_SUCCESS:
      return new CampaignsState();
    default:
      return state;
  }
};
