import React, { Component } from "react";
import "./progress-circle.css";

export default class ProgressCircle extends Component {
  render() {
    return (
      <div className="progress-circle__container">
        <div className="spinner spinner-muted spinner-lg progress-circle__spinner" />
      </div>
    );
  }
}
