import React, { Component } from "react";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import { campaignActions } from "../../../campaigns";

// To prevent request spam during search, wait for 500ms of idling (no user input) and then make the request
const SEARCH_AFTER_IDLE_FOR_MS = 500;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = { searchQuery: "" };
    this.timeoutID = null;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.campaignSearch) {
      this.setState({
        searchQuery: nextProps.campaignSearch.query || ""
      });
    } else {
      this.setState({ searchQuery: "" });
    }
  }

  runSearch = newQuery => {
    const { setCampaignSearch, loadCampaigns } = this.props;
    setCampaignSearch(newQuery);
    loadCampaigns();
  };

  clearTimeout = () => {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      this.timeoutID = null;
    }
  };

  waitForIDLE = newQuery => {
    // Clear previous timeout
    this.clearTimeout();

    this.timeoutID = setTimeout(() => {
      this.runSearch(newQuery);
      this.timeoutID = null;
    }, SEARCH_AFTER_IDLE_FOR_MS);
  };

  updateGlobalState = (triggeredByEnter, newQuery) => {
    if (triggeredByEnter) {
      // Search immediately
      this.clearTimeout(); // Clear all possibel pending idle timeouts
      this.runSearch(newQuery);
    } else {
      // Wait for the user to be idle
      this.waitForIDLE(newQuery);
    }
  };
  onButton = ev => {
    if (ev.key !== "Enter") return;

    const { searchQuery } = this.state;
    this.updateGlobalState(true, searchQuery);
  };
  onSearch = ev => {
    const newQuery = ev.target.value;
    this.updateGlobalState(false, newQuery);
    this.setState({ searchQuery: newQuery });
  };
  render() {
    const { searchQuery } = this.state;
    const placeholder = `Search...`;
    return (
      <Input
        size="lg"
        type="text"
        placeholder={placeholder}
        value={searchQuery}
        onChange={this.onSearch}
        onKeyDown={this.onButton}
      />
    );
  }
}

const mapStateToProps = state => ({
  campaignSearch: state.campaigns.search
});

const mapDispatchToProps = {
  setCampaignSearch: campaignActions.setSearch,
  loadCampaigns: campaignActions.loadCampaigns
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
