import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Progress } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import "./campaign.css";

class CampaignCard extends Component {
  render() {
    const { data, location } = this.props;
    const { campaignType } = this.props.match.params;

    const separateThousands = number => {
      if (!number) return number;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const today = moment();
    const dateStart = moment(data.start_time);
    let dateStartText = data.start_time
      ? dateStart.clone().format("MMMM Do YYYY")
      : null;
    const dateEnd = data.end_time ? moment(data.end_time) : 0;

    let dateEndText = dateEnd ? dateEnd.clone().format("MMMM Do YYYY") : null;

    dateEndText = dateEndText === "January 1st 1970" ? null : dateEndText;

    let campaignRunTime = dateEnd
      ? Math.ceil(dateEnd.diff(dateStart, "d", true))
      : 0;

    let campaignCurrentProgress = Math.ceil(
      Math.abs(dateStart.diff(today, "d", true))
    );

    if (!dateEnd) campaignRunTime = 1;
    if (campaignCurrentProgress > campaignRunTime) {
      campaignCurrentProgress = campaignRunTime;
    }

    let status = data.status;

    const progressActive = data.status === "Running";

    if (status === "Scheduled" || status === "New") {
      campaignCurrentProgress = 0;
    }
    if (!data.reach && status !== "Scheduled") {
      if (!status) {
        // This means that we didn't have any data syncs so far
        // Fill in blank data
        status = "";
        campaignCurrentProgress = 0;
        dateStartText = "";
        dateEndText = "";
      } else {
        status += " - ";
      }
      status += "Synchronizing data...";
    }

    const campaignLink = `/${campaignType}/${data.id}`;

    let cardClassName = "cards-item p-4 rounded ";
    // Check if already selected
    if (location.pathname.indexOf(campaignLink) !== -1) {
      cardClassName += "bg-faded";
    }

    // Check for a preview img URL being available
    const previewImg = data.tracking_data
      ? data.tracking_data.preview_url
        ? data.tracking_data.preview_url
        : null
      : null;

    return (
      <Link to={campaignLink}>
        <div className={cardClassName} style={{ marginTop: "28px" }}>
          {previewImg && (
            <Link to={`${campaignLink}/preview`}>
              <img
                src={previewImg}
                alt="Ad Preview"
                width="90"
                className="float-right ml-5"
              />
            </Link>
          )}
          <h4 style={{ color: "black" }}>{data.title || data.role}</h4>
          <p className="my-2 text-muted">
            <span className="badge badge-success">{data.category}</span>
            {data.locations.map(location => (
              <span
                style={{ marginLeft: "3px" }}
                className="badge badge-secondary"
              >
                {location}
              </span>
            ))}
          </p>
          <div className="d-flex flex-row text-muted">
            <div className="py-3">
              <div className="d-flex flex-row align-items-center">
                <span>
                  <strong>{separateThousands(data.reach.impressions)}</strong>
                  <br />
                  impressions
                </span>
                <span className="px-3">
                  <ion-icon
                    name="arrow-round-forward"
                    style={{ marginLeft: "14.4px" }}
                  />
                </span>
                <span>
                  <strong>{separateThousands(data.reach.link_clicks)}</strong>
                  <br />
                  clicked
                </span>
                <span className="px-3">
                  <ion-icon name="heart" style={{ marginLeft: "14.4px" }} />
                </span>
                <span className="pr-4">
                  <strong>{separateThousands(data.reach.reactions)}</strong>
                  <br />
                  reacts
                </span>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div>
            <small className="text-muted">{status}</small>
          </div>
          <Progress
            style={{ height: "0.5rem" }}
            max={campaignRunTime}
            value={campaignCurrentProgress}
            animated={progressActive}
            striped={progressActive}
            color="success"
          />
          <div>
            <small className="text-muted">{dateStartText}</small>
            <small className="text-muted float-right">{dateEndText}</small>
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps, null)(CampaignCard));
