import React, { Component } from "react";
import { Route } from "react-router-dom";
import CampaignDisplay from "../campaign-display";
import CampaignDetails from "../campaign-details";
import CustomerMessage from "../customer-message";
export class CampaignManager extends Component {
  render() {
    return (
      <div className="container-fluid p-0" style={{ minHeight: "0px" }}>
        <div
          className="column align-self-md-center d-flex flex-column"
          style={{ minHeight: "0px", height: "100vh", overflow: "hidden" }}
        >
          <CustomerMessage />
          <div className="row align-self-md-center w-100 flex-grow-1 h-100">
            <Route path="/:campaignType" component={CampaignDisplay} />
            <Route path="/:campaignType" component={CampaignDetails} />
          </div>
        </div>
      </div>
    );
  }
}
