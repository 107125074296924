import {
  LOAD_USER_SUCCESS,
  SET_CURRENT_ORGANIZATION,
  RESET_USER
} from "./action-types";
import Immutable from "immutable";

const UserState = null;

export const userReducer = (state = UserState, { type, payload }) => {
  switch (type) {
    case LOAD_USER_SUCCESS:
      if (state !== null) {
        const newState = Immutable.fromJS(payload);
        const oldOrgDataCurrent = state.get("orgData").get("current");
        return newState.setIn(["orgData", "current"], oldOrgDataCurrent);
      }
      return Immutable.fromJS(payload);
    case SET_CURRENT_ORGANIZATION:
      return state.setIn(["orgData", "current"], payload);
    case RESET_USER:
      return null;
    default:
      return state;
  }
};
