import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authActions } from "src/auth";
import { userActions } from "src/user";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import "./sign-in-page.css";

class SignInPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isPending: false,
      isResetting: false,
      isResetDone: false,
      resetError: null
    };
  }
  componentWillMount = () => {
    const { resetUser } = this.props;
    resetUser();
  };

  handleEmail = ev => {
    this.setState({ email: ev.target.value });
  };
  handlePassword = ev => {
    this.setState({ password: ev.target.value });
  };
  auth = () => {
    const { email, password } = this.state;
    this.setState({ isPending: true });
    const { formAuth } = this.props;
    formAuth(email, password);
  };

  resetPassword = () => {
    this.setState({ isPending: true });
    const request = new XMLHttpRequest();
    request.open("POST", "/api/users/reset");
    request.setRequestHeader("Content-Type", "application/json");
    request.onload = event => {
      const { status } = request;
      if (status === 200) {
        this.setState({ isPending: false, isResetDone: true });
      } else {
        this.setState({
          isPending: false,
          isResetDone: true,
          resetError: status
        });
      }
    };
    request.send(JSON.stringify({ email: this.state.email }));
  };

  toggleReset = () => {
    if (this.state.isResetting) {
      this.setState({
        isResetting: false,
        isPending: false,
        isResetDone: false,
        resetError: false
      });
    } else {
      this.setState({ isResetting: true });
    }
  };

  onSubmit = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.state.isResetting) {
      this.resetPassword();
    } else {
      this.auth();
    }
  };

  render() {
    let { isResetting, isPending, isResetDone, resetError } = this.state;
    const loginError = this.props.authError;
    if (loginError) {
      isPending = false;
    }
    return (
      <div className="bg-faded">
        <div className="hv-center">
          <Container style={{ width: "800px" }}>
            <Row className="rounded shdw animated fadeInUp delay tease">
              <div className="col-12 col-md-5 bg-adway text-white p-6 rounded-left">
                <h2 className="h-adway">Sign in</h2>
                <p className="my-3">
                  Sign in and manage your ads within adway's advertising
                  platform.
                </p>

                <img
                  alt="Adway Logo"
                  src="/images/adway-round.png"
                  width="48"
                  className="mt-10"
                />
              </div>
              <Col className="bg-white p-6 rounded-right col-7">
                <Form>
                  <FormGroup row>
                    <Col className="form-group mb-4 col-12">
                      <Label for="email">E-mail</Label>
                      <Input
                        id="email"
                        type="email"
                        className="form-control form-control-lg"
                        placeholder=""
                        value={this.state.email}
                        disabled={isPending}
                        onChange={this.handleEmail}
                      />
                    </Col>
                    {!isResetting && (
                      <Col className="form-group col-12 mb-4 col-12">
                        <Label for="repeat">Password</Label>
                        <Input
                          id="repeat"
                          type="password"
                          className="form-control form-control-lg"
                          placeholder=""
                          value={this.state.password}
                          disabled={isPending}
                          onChange={this.handlePassword}
                        />
                      </Col>
                    )}
                  </FormGroup>
                  <nav className="navbar px-0 mt-4">
                    <Button
                      style={{ height: "44px", width: "86px" }}
                      type="submit"
                      size="lg"
                      color="primary"
                      onSubmit={this.onSubmit}
                      onClick={this.onSubmit}
                      className={isPending || isResetDone ? "disabled" : ""}
                    >
                      {isPending ? (
                        <span className="spinner" />
                      ) : isResetting ? (
                        isResetDone ? (
                          "Done"
                        ) : (
                          "Reset"
                        )
                      ) : (
                        "Sign in"
                      )}
                    </Button>
                    <a href="#reset" onClick={this.toggleReset}>
                      <small className="navbar-text text-muted">
                        {isResetting
                          ? "Remeber your password?"
                          : "Forgot your password?"}
                      </small>
                    </a>
                  </nav>
                </Form>

                {isResetDone && (
                  <Alert
                    color="warning"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    <strong style={{ margin: "auto", textAlign: "center" }}>
                      {resetError
                        ? "Failed to reset password"
                        : "Please check your email"}
                    </strong>
                  </Alert>
                )}
                {loginError &&
                  !isResetDone &&
                  !isResetting && (
                    <Alert
                      color="danger"
                      style={{ display: "flex", marginTop: "20px" }}
                    >
                      <strong style={{ margin: "auto", textAlign: "center" }}>
                        {`${loginError}`}
                      </strong>
                    </Alert>
                  )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authError: state.auth.error };
};

const mapDispatchToProps = {
  formAuth: authActions.formAuth,

  resetUser: userActions.resetUser
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignInPage)
);
