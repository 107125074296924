import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig, devConfig } from "./config";

const STAGE =
  process.env.NODE_ENV === "development"
    ? "develop"
    : process.env.REACT_APP_STAGE === "production"
      ? "production"
      : "develop";

let firebaseAppInstance = null;
if (STAGE === "production") {
  firebaseAppInstance = firebase.initializeApp(firebaseConfig);
} else {
  console.log(`CONNECTED TO DEV SERVER`);
  firebaseAppInstance = firebase.initializeApp(devConfig);
}

export const firebaseApp = firebaseAppInstance;
export const firebaseAuth = firebase.auth();
