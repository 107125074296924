import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { campaignsReducer } from "./campaigns";
import { userReducer } from "./user";
import { orgReducer } from "./organization";
import { adDataReducer } from "./adData";
import { notificationReducer } from "./notifications";

import history from "./history";

export default combineReducers({
  auth: authReducer,
  campaigns: campaignsReducer,
  user: userReducer,
  organization: orgReducer,
  adData: adDataReducer,
  notifications: notificationReducer,
  router: connectRouter(history)
});
