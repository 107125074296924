import React, { useEffect } from "react";
import { connect } from "react-redux";

import { resetAdUpdateCompleted } from "../../../notifications/actions";

const NotificationBar = ({ adUpdateCompleted, resetAdUpdateCompleted }) => {
  useEffect(
    () => {
      if (adUpdateCompleted) {
        setTimeout(() => resetAdUpdateCompleted(), 5000);
      }
    },
    [adUpdateCompleted]
  );
  return (
    adUpdateCompleted && (
      <div style={{ position: "absolute", top: 0, zIndex: 10, width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: "15px 30px",
            color: "white",
            backgroundColor: "#6FCF97"
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM8 15L3 10L4.4 8.6L8 12.2L15.6 4.6L17 6L8 15Z"
              fill="white"
            />
          </svg>

          <span style={{ marginLeft: "20px" }}>
            Your ad update has been completed
          </span>
        </div>
      </div>
    )
  );
};

const mapStateToProps = state => {
  return {
    adUpdateCompleted: state.notifications.adUpdateCompleted
  };
};

const mapDispatchToProps = {
  resetAdUpdateCompleted: resetAdUpdateCompleted
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBar);
