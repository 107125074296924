export const firebaseConfig = {
  apiKey: "AIzaSyAsp1Lrf5t7_mj9ZO9vLTlqJ9_VM22rJe8",
  authDomain: "adway-leads.firebaseapp.com",
  databaseURL: "https://adway-leads.firebaseio.com",
  projectId: "adway-leads",
  storageBucket: "adway-leads.appspot.com",
  messagingSenderId: "942013271766"
};

export const devConfig = {
  apiKey: "AIzaSyAdQU3rhkUBqVrmvSPT0JojjNNCvxgd5eQ",
  authDomain: "adway-test.firebaseapp.com",
  databaseURL: "https://adway-test.firebaseio.com",
  projectId: "adway-test",
  storageBucket: "adway-test.appspot.com",
  messagingSenderId: "322191405980"
};
