import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { userActions } from "../../../user";
import { connect } from "react-redux";
import { CampaignManager } from "../../components/campaign-manager";
import Insights from "../../components/insights";
import "./dashboard.css";

class Dashboard extends Component {
  componentWillMount() {
    this.props.loadUser();
  }
  render() {
    // We don't have a root page. Redirect to /jobs by default.
    if (this.props.location.pathname === "/") return <Redirect to="/jobs" />;

    return (
      <div className="d-flex flex-row h-100" style={{ width: "100%" }}>
        <Route component={Sidebar} />
        <Switch>
          <Route path="/insights" component={Insights} />
          <Route path="/" component={CampaignManager} />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = {
  loadUser: userActions.loadUser
};

export default connect(
  null,
  mapDispatchToProps
)(Dashboard);
