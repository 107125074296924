import React, { Component } from "react";
import { connect } from "react-redux";
import { adDataActions } from "../../../adData";

const PreviewSelector = ({ selected, onClickHandler, platform, children }) => {
  return (
    <div
      className="tab-item text-center active"
      style={{
        width: "120px",
        height: "120px",
        background: selected
          ? "rgba(255, 255, 255, 0.20)"
          : "rgba(255, 255, 255, 0.1)",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.05)",
        borderRadius: "5px",
        cursor: "pointer"
      }}
      onClick={onClickHandler}
    >
      <div
        style={{
          position: "static",
          height: "6px",
          width: "120px",
          top: "0px",
          background:
            selected &&
            "linear-gradient(269.91deg, #FF47BD 1.15%, #B969FF 100%)",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.05)",
          borderRadius: "5px"
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        {children}
        <span
          style={{
            width: "110px",
            height: "30px",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "15px",
            lineHeight: "15px",
            textAlign: "center",
            letterSpacing: "0.04em",
            paddingTop: "10px",
            color: selected
              ? "rgba(255, 255, 255, 1)"
              : "rgba(255, 255, 255, 0.6)",
            opacity: selected ? "1" : "0.8"
          }}
        >
          {platform} Preview
        </span>
      </div>
    </div>
  );
};

class CampaignPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAd: "facebook"
    };
  }
  componentDidMount() {
    const { campaignID } = this.props.match.params;

    const campaignData =
      this.props.campaigns &&
      this.props.campaigns.find(campaignData => campaignData.id === campaignID);

    if (campaignData) {
      const adData = campaignData.tracking_data
        ? campaignData.tracking_data.ad_data
        : {};
      this.props.setAdData(adData);
    }
  }
  render() {
    const { campaignID } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (
      !campaignData ||
      !campaignData.tracking_data ||
      !campaignData.tracking_data.ad_data
    )
      return null;

    const adData = this.props.adData || campaignData.tracking_data.ad_data;

    const { selectedImage, selectedAdBody } = (() => {
      switch (this.state.selectedAd) {
        case "facebook":
          return {
            selectedImage: adData.adImageURL,
            selectedAdBody: adData.adBody
          };
        case "instagram":
          return {
            selectedImage: adData.adImageInUrl || adData.adImageURL, // We currently do not save adImageInUrl in adData
            selectedAdBody: adData.adBodyIn || adData.adBody
          };
        case "linkedIn":
          return {
            selectedImage: adData.adImageLinkedIn || adData.adImageURL,
            selectedAdBody: adData.adBodyLn || adData.adBody
          };
        default:
          return null;
      }
    })();

    let renderedAdBody = [];

    const hasCompleteAdData =
      selectedAdBody && adData.adPageName && adData.adTitle; // && adData.adLogoURL;

    selectedAdBody &&
      selectedAdBody.split("\n").forEach(textSegment => {
        renderedAdBody.push(textSegment);
        renderedAdBody.push(<br />);
      });

    const campaignHasLinkedInAds =
      (!!campaignData.tracking_data &&
        !!campaignData.tracking_data.ads &&
        !!campaignData.tracking_data.ads.ln &&
        !!campaignData.tracking_data.ads.ln.length > 0) ||
      (campaignData.tracking_data.ad_data &&
        campaignData.tracking_data.ad_data.adImageLinkedIn);

    const campaignHasInstagramAds =
      (!!campaignData.tracking_data &&
        !!campaignData.tracking_data.ads &&
        !!campaignData.tracking_data.ads.in &&
        !!campaignData.tracking_data.ads.in.length > 0) ||
      (campaignData.tracking_data.ad_data &&
        campaignData.tracking_data.ad_data.adImageInUrl);

    return (
      <>
        <div className="col-12">
          <p className="text-op-75">
            See what your job ad will look like in social media before it's
            published.
          </p>

          {hasCompleteAdData ? (
            <div className="iphone">
              <div className="iphone-top" />
              <div className="iphone-body">
                <div className="iphone-wrap container">
                  <div className="row">
                    <div className="col-2">
                      <img
                        alt={adData.adPageName}
                        src={adData.adLogoURL}
                        width="32"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="col-10">
                      <strong>{adData.adPageName}</strong>
                      <br />
                      <small className="text-muted">Sponsored</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <p>{renderedAdBody}</p>
                    </div>
                  </div>
                  <div className="row">
                    <img
                      alt={adData.adTitle}
                      src={selectedImage}
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="row py-2">
                    <div
                      className="col-9"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "200px"
                      }}
                    >
                      <strong>{adData.adTitle}</strong>
                      <br />
                      <small className="text-muted">{adData.adLinkDesc}</small>
                    </div>
                    <div className="col-3 text-right">
                      <button className="btn btn-outline-secondary btn-sm my-1">
                        Apply now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.selectedAd === "facebook" && (
                <div className="iphone-bottom-facebook" />
              )}
              {this.state.selectedAd === "instagram" && (
                <div className="iphone-bottom-instagram" />
              )}
              {this.state.selectedAd === "linkedIn" && (
                <div className="iphone-bottom-linkedin" />
              )}
            </div>
          ) : null}
        </div>

        {hasCompleteAdData ? (
          <div className="d-flex flex-col w-100 align-items-center justify-content-center">
            <PreviewSelector
              onClickHandler={() => {
                this.setState({ selectedAd: "facebook" });
              }}
              platform="Facebook"
              selected={this.state.selectedAd === "facebook"}
            >
              <svg
                className="w-full"
                width="16"
                height="30"
                viewBox="0 0 16 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.1831 4.86742H16V0.207786C15.5149 0.144818 13.8435 0.00170898 11.8974 0.00170898C7.83571 0.00170898 5.05388 2.42884 5.05388 6.88812V10.9925H0.571411V16.2017H5.05388V29.3105H10.5474V16.2017H14.8487L15.5325 10.9925H10.5474V7.40331C10.5474 5.8978 10.974 4.86742 13.1831 4.86742Z"
                  fill={
                    this.state.selectedAd === "facebook"
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(255, 255, 255, 0.3)"
                  }
                  fill-opacity={
                    this.state.selectedAd === "facebook"
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(255, 255, 255, 0.3)"
                  }
                />
              </svg>
            </PreviewSelector>
            {campaignHasInstagramAds && (
              <PreviewSelector
                onClickHandler={() => {
                  this.setState({ selectedAd: "instagram" });
                }}
                platform="Instagram"
                selected={this.state.selectedAd === "instagram"}
              >
                <svg
                  width="26"
                  height="27"
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.9787 7.07306C9.29531 7.07306 6.32427 10.0097 6.32427 13.6504C6.32427 17.291 9.29531 20.2276 12.9787 20.2276C16.6621 20.2276 19.6331 17.291 19.6331 13.6504C19.6331 10.0097 16.6621 7.07306 12.9787 7.07306ZM12.9787 17.9265C10.5984 17.9265 8.65245 16.0088 8.65245 13.6504C8.65245 11.2919 10.5926 9.37426 12.9787 9.37426C15.3648 9.37426 17.305 11.2919 17.305 13.6504C17.305 16.0088 15.359 17.9265 12.9787 17.9265ZM21.4575 6.80402C21.4575 7.65695 20.7625 8.33815 19.9053 8.33815C19.0424 8.33815 18.3532 7.65122 18.3532 6.80402C18.3532 5.95681 19.0482 5.26989 19.9053 5.26989C20.7625 5.26989 21.4575 5.95681 21.4575 6.80402ZM25.8648 8.36104C25.7663 6.306 25.2914 4.48565 23.7683 2.98586C22.2509 1.48608 20.4092 1.01668 18.33 0.913645C16.1872 0.793433 9.76442 0.793433 7.62156 0.913645C5.54821 1.01096 3.70651 1.48036 2.18334 2.98014C0.66018 4.47992 0.191069 6.30027 0.0868216 8.35532C-0.0347999 10.4733 -0.0347999 16.8217 0.0868216 18.9397C0.185277 20.9947 0.66018 22.8151 2.18334 24.3148C3.70651 25.8146 5.54242 26.284 7.62156 26.3871C9.76442 26.5073 16.1872 26.5073 18.33 26.3871C20.4092 26.2897 22.2509 25.8204 23.7683 24.3148C25.2856 22.8151 25.7605 20.9947 25.8648 18.9397C25.9864 16.8217 25.9864 10.4791 25.8648 8.36104ZM23.0965 21.2122C22.6447 22.3342 21.7702 23.1986 20.6293 23.6508C18.9208 24.3206 14.8667 24.166 12.9787 24.166C11.0907 24.166 7.03083 24.3148 5.32813 23.6508C4.193 23.2043 3.31848 22.3399 2.86095 21.2122C2.18334 19.5236 2.33972 15.5165 2.33972 13.6504C2.33972 11.7842 2.18914 7.77143 2.86095 6.08847C3.31269 4.96649 4.1872 4.10212 5.32813 3.64989C7.03662 2.98014 11.0907 3.1347 12.9787 3.1347C14.8667 3.1347 18.9266 2.98586 20.6293 3.64989C21.7644 4.09639 22.6389 4.96077 23.0965 6.08847C23.7741 7.77716 23.6177 11.7842 23.6177 13.6504C23.6177 15.5165 23.7741 19.5293 23.0965 21.2122Z"
                    fill={
                      this.state.selectedAd === "instagram"
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(255, 255, 255, 0.3)"
                    }
                    fill-opacity={
                      this.state.selectedAd === "instagram"
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(255, 255, 255, 0.3)"
                    }
                  />
                </svg>
              </PreviewSelector>
            )}
            {campaignHasLinkedInAds && (
              <PreviewSelector
                onClickHandler={() => {
                  this.setState({ selectedAd: "linkedIn" });
                }}
                platform="LinkedIn"
                selected={this.state.selectedAd === "linkedIn"}
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.92516 26.251H0.437237V8.72561H5.92516V26.251ZM3.17824 6.33498C1.42339 6.33498 0 4.89358 0 3.15334C1.25605e-08 2.31745 0.33485 1.51578 0.930886 0.924715C1.52692 0.333646 2.33532 0.00158691 3.17824 0.00158691C4.02117 0.00158691 4.82956 0.333646 5.4256 0.924715C6.02164 1.51578 6.35649 2.31745 6.35649 3.15334C6.35649 4.89358 4.93251 6.33498 3.17824 6.33498ZM26.4647 26.251H20.9886V17.7197C20.9886 15.6865 20.9472 13.0791 18.1353 13.0791C15.282 13.0791 14.8448 15.2881 14.8448 17.5733V26.251H9.36279V8.72561H14.6262V11.1162H14.703C15.4357 9.73928 17.2254 8.28616 19.8955 8.28616C25.4496 8.28616 26.4706 11.9131 26.4706 16.624V26.251H26.4647Z"
                    fill={
                      this.state.selectedAd === "linkedIn"
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(255, 255, 255, 0.3)"
                    }
                    fill-opacity={
                      this.state.selectedAd === "linkedIn"
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(255, 255, 255, 0.3)"
                    }
                  />
                </svg>
              </PreviewSelector>
            )}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaigns.list,
  adData: state.adData
});

const mapDispatchToProps = {
  setAdData: adDataActions.setAdData,
  resetAdData: adDataActions.resetAdData
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPreview);
