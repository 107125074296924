import { SET_AD_DATA, RESET_AD_DATA } from "./actions-types";

const AdDataState = {
  adBody: "",
  adBodyIn: "",
  adBodyLn: "",
  adCategory: "",
  adLocation: "",
  adImage: "",
  adImageLinkedIn: "",
  adImageIn: "",
  adImageURL: "",
  adLink: "",
  adLinkDesc: "",
  adLogoURL: "",
  adPageName: "",
  adTitle: "",
  date_end: "",
  id: "",
  employer: "",
  internal_id: ""
};

export const adDataReducer = (state = AdDataState, { type, payload }) => {
  switch (type) {
    case SET_AD_DATA:
      return { ...payload };

    case RESET_AD_DATA:
      return AdDataState;
    default:
      return state;
  }
};
