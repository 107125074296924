import {
  LOAD_CAMPAIGNS_SUCCESS,
  LOAD_CAMPAIGN_FILTERS_SUCCESS,
  UNLOAD_CAMPAIGNS_SUCCESS,
  SET_FILTER,
  LOAD_CAMPAIGNS_STATUS,
  SET_SEARCH,
  SET_TYPE_FILTER,
  RESET_FILTER_AND_SEARCH,
  RESET_CAMPAIGNS
} from "./actions-types";
import { tokenUtils } from "../auth";

// Infinite scroll constants
const INITIAL_CAMPAIGN_LOAD_COUNT = 10;
const EXTRA_CAMPAIGN_LOAD_COUNT = 10;

const stateFiltersToAPI = (typeFilter, stateFilters) => {
  const apiFilters = {};

  const migrateFilter = (apiField, filters) => {
    Object.values(filters).forEach(option => {
      if (option.active) {
        if (!apiFilters[apiField]) {
          apiFilters[apiField] = "";
        } else {
          apiFilters[apiField] += ",";
        }
        apiFilters[apiField] += option.name;
      }
    });
  };

  if (stateFilters) {
    migrateFilter("category", stateFilters.category);
    migrateFilter("locations", stateFilters.location);
    migrateFilter("status", stateFilters.status);

    if (typeFilter) apiFilters["type"] = typeFilter;
  }

  return apiFilters;
};

export function loadCampaigns(fullLoad = true) {
  return async (dispatch, getState) => {
    const { user, campaigns } = getState();

    if (user === null) {
      dispatch(setLoadStatus("NO_USER"));
    } else {
      dispatch(setLoadStatus("loading"));

      const apiFilters = stateFiltersToAPI(
        campaigns.typeFilter,
        campaigns.filter
      );

      const campaignResp = await tokenUtils.makeRequest(
        "/campaigns",
        {
          organization_id: user.get("orgData").get("current"), // Filter by organization
          title_regex: campaigns.search.query // Set a regex based search query by title
            ? campaigns.search.query + ",i"
            : undefined,
          ...apiFilters, // Set filters
          _sort: "priority,start_time", // Sort by status display prioroty and then start_time
          _order: "desc,desc", // Descending ordering
          _start: fullLoad ? 0 : campaigns.list.length, // If partially loading, continue where we left off
          _end: fullLoad // If partially loading, continue where we left off
            ? INITIAL_CAMPAIGN_LOAD_COUNT
            : campaigns.list.length + EXTRA_CAMPAIGN_LOAD_COUNT
        },
        true
      );

      const fullCampaignCount = campaignResp.headers.get("X-Total-Count");
      const campaignList = await campaignResp.json();

      dispatch(
        loadCampaignsSuccess({
          list: campaignList,
          user,
          campaignCount: parseInt(fullCampaignCount),
          fullLoad
        })
      );
    }
  };
}

export function loadCampaignFilters() {
  return async (dispatch, getState) => {
    const { user } = getState();

    if (user) {
      const filters = await tokenUtils.makeRequest(
        `/campaigns/filters/${user.get("orgData").get("current")}`
      );

      dispatch(loadCampaignFiltersSuccess(filters));
    }
  };
}

export function setLoadStatus(status) {
  return { type: LOAD_CAMPAIGNS_STATUS, payload: status };
}

export function unloadCampaigns() {
  return {
    type: UNLOAD_CAMPAIGNS_SUCCESS,
    payload: ""
  };
}

export function loadCampaignsSuccess(campaigns) {
  return {
    type: LOAD_CAMPAIGNS_SUCCESS,
    payload: campaigns
  };
}

export function loadCampaignFiltersSuccess(filters) {
  return {
    type: LOAD_CAMPAIGN_FILTERS_SUCCESS,
    payload: filters
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter
  };
}

export function setSearch(query) {
  return {
    type: SET_SEARCH,
    payload: query
  };
}

export function setTypeFilter(type) {
  return {
    type: SET_TYPE_FILTER,
    payload: type
  };
}

export function resetFilterAndSearch() {
  return {
    type: RESET_FILTER_AND_SEARCH,
    payload: null
  };
}

export function resetCampaigns() {
  return {
    type: RESET_CAMPAIGNS,
    payload: null
  };
}
