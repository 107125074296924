import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, Nav, NavItem, NavbarBrand } from "reactstrap";
import Filter from "../filter";
import Search from "../search";
import "./menu.css";

class Menu extends Component {
  renderFilters() {
    const { campaignFilters } = this.props;
    let source = campaignFilters;
    if (source !== null) {
      const filterTypes = Object.keys(source);
      return filterTypes.map(filterType => {
        return (
          <NavItem key={`${filterType}-navitem`}>
            <Filter
              name={filterType}
              options={source[filterType]}
              key={filterType}
            />
          </NavItem>
        );
      });
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="menu col-12 p-0">
        <div className="row">
          <div className="col-12">
            <Search />
          </div>
          <Navbar expand="lg" light>
            <NavbarBrand className="menu__filter-head pl-0 pr-4">
              <p style={{ fontSize: "14.4px", margin: "auto" }}>Filter</p>
            </NavbarBrand>
            <Nav>{this.renderFilters()}</Nav>
          </Navbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaignFilters: state.campaigns.filter,
  campaigns: state.campaigns
});
export default connect(
  mapStateToProps,
  null
)(Menu);
