import React from "react";
import ReactDOM from "react-dom";
import history from "./history";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router/immutable";
import "bootstrap/dist/css/bootstrap.css";
import { initAuth } from "./auth";
import configureStore from "./store";
import { unregister } from "./utils/register-service-worker";
import App from "./views/app";
import "./index.css";
import "./theme.css";
import "./animations.css";
import "./pie.css";

const store = configureStore();
const rootElement = document.getElementById("root");

function render(Component) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <Component />
        </div>
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
}

if (module.hot) {
  module.hot.accept("./views/app", () => {
    render(require("./views/app").default);
  });
}

// Drop service workers.
unregister();

initAuth(store.dispatch)
  .then(() => render(App))
  .catch(error => console.error(error));
