export const LOAD_CAMPAIGNS_SUCCESS = "LOAD_CAMPAIGNS_SUCCESS";
export const LOAD_CAMPAIGN_FILTERS_SUCCESS = "LOAD_CAMPAIGN_FILTERS_SUCCESS";
export const UNLOAD_CAMPAIGNS_SUCCESS = "UNLOAD_CAMPAIGNS_SUCCESS";
export const SET_FILTER = "SET_CAPAIGNS_FILTER";
export const LOAD_CAMPAIGNS_DENIED = "LOAD_CAMPAIGNS_DENIED";
export const LOAD_CAMPAIGNS_STATUS = "LOAD_CAMPAIGNS_STATUS";
export const SET_SEARCH = "SET_CAMPAIGNS_SEARCH";
export const SET_TYPE_FILTER = "SET_CAMPAIGNS_TYPE_FILTER";
export const RESET_FILTER_AND_SEARCH = "RESET_CAMPAIGNS_FILTER_AND_SEARCH";
export const RESET_CAMPAIGNS = "RESET_CAMPAIGNS";
