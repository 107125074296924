import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

class CampaignNavigation extends Component {
  render() {
    const { campaignID, campaignType } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData) {
      return null;
    }

    return (
      <div>
        <div className="py-4">
          <strong>{campaignData.title}</strong>
        </div>
        <div className="topnav d-flex flex-row">
          <NavLink
            to={`/${campaignType}/${campaignID}/`}
            className="tab-item p-3 text-center"
            exact
          >
            <ion-icon name="bookmarks" style={{ fontSize: "2em" }} />
            <br />
            <small>Summary</small>
          </NavLink>
          {campaignData.tracking_data && campaignData.tracking_data._id && (
            <NavLink
              to={`/${campaignType}/${campaignID}/preview`}
              className="tab-item p-3 text-center"
            >
              <ion-icon name="search" style={{ fontSize: "2em" }} />
              <br />
              <small>Preview</small>
            </NavLink>
          )}
          <NavLink
            to={`/${campaignType}/${campaignID}/share`}
            className="tab-item p-3 text-center"
          >
            <ion-icon name="share" style={{ fontSize: "2em" }} />
            <br />
            <small>Share</small>
          </NavLink>
          <NavLink
            to={`/${campaignType}/${campaignID}/channels`}
            className="tab-item p-3 text-center"
          >
            <ion-icon name="git-network" style={{ fontSize: "2em" }} />
            <br />
            <small>Channels</small>
          </NavLink>
          {campaignData.landing_page_id && (
            <NavLink
              to={`/${campaignType}/${campaignID}/apply`}
              className="tab-item p-3 text-center"
            >
              <ion-icon name="globe" style={{ fontSize: "2em" }} />
              <br />
              <small>Apply</small>
            </NavLink>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaigns.list
});
export default connect(mapStateToProps)(CampaignNavigation);
