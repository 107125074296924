import {
  SET_AD_UPDATE_COMPLETED,
  RESET_AD_UPDATE_COMPLETED
} from "./actions-types";

export const setAdUpdateCompleted = () => {
  return { type: SET_AD_UPDATE_COMPLETED, payload: null };
};

export function resetAdUpdateCompleted() {
  return { type: RESET_AD_UPDATE_COMPLETED, payload: null };
}
