import { SET_AD_DATA, RESET_AD_DATA } from "./actions-types";

export const setAdData = adData => {
  return {
    type: SET_AD_DATA,
    payload: adData
  };
};

export function resetAdData() {
  return {
    type: RESET_AD_DATA,
    payload: null
  };
}
