import { firebaseAuth } from "src/firebase";
import {
  INIT_AUTH,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS
} from "./action-types";

export function formAuth(email, password) {
  return dispatch => {
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        return firebaseAuth.currentUser.getIdToken(true).then(authToken => {
          const authData = {
            auth_token: authToken,
            ...result
          };
          return dispatch(signInSuccess(authData));
        });
      })
      .catch(error => dispatch(signInError(error)));
  };
}

export function initAuth(user) {
  return {
    type: INIT_AUTH,
    payload: user
  };
}

export function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    payload: error
  };
}

export function signInSuccess(result) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: result
  };
}

export function signOut() {
  return dispatch => {
    firebaseAuth.signOut().then(() => dispatch(signOutSuccess()));
  };
}

export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS
  };
}
