import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { tokenUtils } from "../../../auth";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export class CampaignSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: {
        boost: false,
        cancel: false,
        publish: false,
        republish: false
      },
      notification: null
    };
    this.notificationToggle = this.notificationToggle.bind(this);
  }

  getFeatures(props) {
    const { campaignID } = props.match.params;

    if (!props.campaigns) {
      return null;
    }

    const campaignData = props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData) return null;

    const trackingID = campaignData.tracking_id;
    const adGenID = campaignData.adgen_id;
    if (trackingID && adGenID) {
      tokenUtils
        .makeRequest("/campaignmanager/getFeatures", {
          action_object_id: trackingID,
          adgen_id: adGenID
        })
        .then(features => {
          this.setState({ features });
        });
    } else {
      this.setState({
        features: {
          boost: false,
          cancel: false,
          publish: false,
          republish: false
        }
      });
    }
  }
  componentDidMount() {
    this.getFeatures(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.getFeatures(nextProps);
  }

  notificationToggle() {
    this.setState({ notification: null });
  }

  onActionClick(actionName) {
    const { campaignID } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }
    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData) return null;

    const {
      requireCostCenter,
      requireBoostType,
      selectCostCenterUrl,
      selectBoostTypeUrl
    } = campaignData;

    const trackingID = campaignData.tracking_id;
    const adGenID = campaignData.adgen_id;
    const orgID = campaignData.organization_id;

    return () => {
      const currentFeatures = this.state.features;
      this.setState({
        features: { ...currentFeatures, [actionName]: "pending" }
      });

      if (
        requireCostCenter &&
        selectCostCenterUrl &&
        actionName === "publish"
      ) {
        return (() => {
          window.open(selectCostCenterUrl, "_blank");
          this.setState({
            features: {
              ...currentFeatures,
              [actionName]: true
            }
          });
        })();
      } else if (
        requireBoostType &&
        selectBoostTypeUrl &&
        actionName === "boost"
      ) {
        return (() => {
          window.open(selectBoostTypeUrl, "_blank");
          this.setState({
            features: {
              ...currentFeatures,
              [actionName]: true
            }
          });
        })();
      }
      tokenUtils
        .makeRequest(
          `/campaignmanager/${actionName}`,
          {
            action_object_id: trackingID,
            adgen_id: adGenID,
            campaign_id: campaignID,
            org_id: orgID
          },
          false,
          "POST"
        )
        .then(status => {
          this.setState({
            features: {
              ...currentFeatures,
              [actionName]: false
            },
            notification: status.message || "Request received."
          });
        });
    };
  }

  renderActionButton(
    label,
    icon,
    actionName,
    buttonClass = "btn-secondary",
    smallIcon = false,
    width = "146px"
  ) {
    const features = this.state.features;

    features["remove"] = true;

    if (features[actionName] === false) return null;
    const isPending =
      !features[actionName] || features[actionName] === "pending";

    const { campaignID } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }
    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData) return null;

    const {
      requireCostCenter,
      selectCostCenterUrl,
      requireBoostType,
      selectBoostTypeUrl
    } = campaignData;

    let showButton = true;

    switch (actionName) {
      case "publish":
        showButton =
          (requireCostCenter && selectCostCenterUrl) || !requireCostCenter;
        break;

      case "boost":
        showButton =
          (requireBoostType && selectBoostTypeUrl) || !requireBoostType;
        break;

      default:
        showButton = true;
        break;
    }

    return (
      showButton && (
        <button
          className={`btn btn-lg ${buttonClass}`}
          onClick={this.onActionClick(actionName)}
          style={{
            width: width,
            height: "38px",
            padding: isPending ? "0" : ""
          }}
        >
          {isPending && (
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <span className="spinner" style={{ margin: "auto" }} />
            </div>
          )}
          {!isPending && label}
          {!isPending && (
            <ion-icon
              name={icon}
              className={`ml-3 ${smallIcon ? "icon-small hydrated" : ""}`}
              size={smallIcon ? "small" : ""}
              style={{ marginLeft: "14.4px" }}
            />
          )}
        </button>
      )
    );
  }

  renderCompleted() {
    return (
      <div className="alert alert-success p-4" role="alert">
        <h4 className="alert-heading h-adway">Completed</h4>
        <p className="text-white">
          Your job ad is no longer visible in social media, publish it again to
          reach a wider audience and gain more traction.
        </p>
        {this.renderActionButton(
          "Publish again",
          "heart",
          "republish",
          "btn-success"
        )}
        {this.renderActionButton("Boost now", "rocket", "boost")}
      </div>
    );
  }
  renderRunning() {
    return (
      <div className="alert alert-success p-4" role="alert">
        <h4 className="alert-heading h-adway">Running</h4>
        <p className="text-white">
          Your job ad is now visible in social media, you can still boost the ad
          to reach a wider audience and gain more traction.
        </p>
        {this.renderActionButton("Boost now", "rocket", "boost")}
      </div>
    );
  }
  renderScheduled() {
    const { campaignID } = this.props.match.params;

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData || !campaignData.tracking_data) return null;

    const adStartDate = moment(campaignData.start_time);
    const startsIn = adStartDate.fromNow();

    return (
      <div className="alert alert-success p-4" role="alert">
        <h4 className="alert-heading h-adway">Scheduled</h4>
        <p className="text-white">
          Your job ad is scheduled and will be visible in social media within 24
          hours.
        </p>
        {this.renderActionButton("Boost now", "rocket", "boost")}
        {this.renderActionButton(
          "Publish now",
          "heart",
          "publish",
          "btn-success"
        )}
        <p className="text-white text-op-75 mb-0 mt-2">
          {Math.abs(adStartDate.diff(moment(), "h")) < 24 && (
            <small>
              Auto published in <strong>{startsIn}</strong>
            </small>
          )}
        </p>
      </div>
    );
  }

  renderWaiting() {
    const { campaignID } = this.props.match.params;

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData || !campaignData.tracking_data) return null;

    return (
      <div className="alert alert-success p-4" role="alert">
        <h4 className="alert-heading h-adway">Waiting Activation</h4>
        <p className="text-white">
          Your job ad is waiting for your activation.
        </p>
        {this.renderActionButton("Boost now", "rocket", "boost")}
        {this.renderActionButton(
          "Activate now",
          "heart",
          "publish",
          "btn-success",
          false,
          "150px"
        )}
      </div>
    );
  }

  renderCancel() {
    return (
      <div>
        <p className="mt-4">
          <small>
            If you don't want your ad to gain traction in social media, you can
            cancel the scheduled job ad.
          </small>
        </p>

        <p className="my-3">
          {this.renderActionButton(
            "Cancel ad",
            "trash",
            "cancel",
            "btn-outline-success",
            true
          )}
        </p>
      </div>
    );
  }

  renderRemove() {
    return (
      <div>
        <p className="mt-4">
          <small>
            Deactivates any ads and removes all current campaign data. This
            allows the ad to regenerate if it is still in the customer feed.
          </small>
        </p>

        <p className="my-3">
          {this.renderActionButton(
            "Remove ad",
            "trash",
            "remove",
            "btn-outline-success",
            true
          )}
        </p>
      </div>
    );
  }

  renderShareLinks() {
    const { campaignID, campaignType } = this.props.match.params;
    return (
      <div className="alert alert-light p-4 mt-4" role="alert">
        <h4 className="alert-heading">Share</h4>
        <p>
          Social media ads works better with data, share it to reach more
          candidates.
        </p>
        <Link
          to={`/${campaignType}/${campaignID}/share`}
          className="btn btn-lg btn-outline-secondary"
        >
          Shareable links
          <ion-icon
            name="share"
            className="ml-3"
            style={{ marginLeft: "14.4px" }}
          />
        </Link>
      </div>
    );
  }

  renderEdit() {
    const { campaignID, campaignType } = this.props.match.params;
    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData) return null;

    // Check for a preview img URL being available
    const previewImg =
      campaignData.tracking_data && campaignData.tracking_data.preview_url;

    return (
      <div className="alert alert-light p-4 mt-4" role="alert">
        {previewImg && (
          <img
            src={previewImg}
            alt="Ad Preview"
            width="90"
            className="float-right ml-3"
          />
        )}
        <h4 className="alert-heading">Edit</h4>
        <p>Edit your job ad before it's published.</p>
        <Link
          to={`/${campaignType}/${campaignID}/edit`}
          className="btn btn-lg btn-outline-secondary"
        >
          Edit
          <ion-icon
            name="cog"
            className="ml-3"
            style={{ marginLeft: "14.4px" }}
          />
        </Link>
        <div className="clearfix" />
      </div>
    );
  }

  renderPreview() {
    const { campaignID, campaignType } = this.props.match.params;

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData) return null;

    // Check for a preview img URL being available
    const previewImg = campaignData.tracking_data
      ? campaignData.tracking_data.preview_url
        ? campaignData.tracking_data.preview_url
        : null
      : null;

    if (!previewImg) return null;
    return (
      <div className="alert alert-light p-4 mt-4" role="alert">
        <img
          src={previewImg}
          alt="Ad Preview"
          width="90"
          className="float-right ml-3"
        />
        <h4 className="alert-heading">Preview</h4>
        <p>
          See how your job ad will look like in social media before it's
          published.
        </p>
        <Link
          to={`/${campaignType}/${campaignID}/preview`}
          className="btn btn-lg btn-outline-secondary"
        >
          Sneak peek
          <ion-icon
            name="search"
            className="ml-3"
            style={{ marginLeft: "14.4px" }}
          />
        </Link>
        <div className="clearfix" />
      </div>
    );
  }

  renderNotification() {
    const notification = this.state.notification;
    if (!notification) return null;
    return (
      <Modal isOpen={this.state.notification} toggle={this.notificationToggle}>
        <ModalHeader toggle={this.notificationToggle}>
          {notification}
        </ModalHeader>
        <ModalBody>
          Your request has been received and will be processed shortly.
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { campaignID } = this.props.match.params;
    const { user } = this.props;

    if (!this.props.campaigns) {
      return null;
    }

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    if (!campaignData) {
      return null;
    }
    const { status } = campaignData;

    let canEditAds = false;
    let isAdmin = false;

    if (user) {
      canEditAds = user.get("canEditAds");
      isAdmin = user.get("isAdmin");
    }

    if (status === "Completed")
      return (
        <div className="col-12">
          {this.renderCompleted()}
          {this.renderNotification()}
          {isAdmin && this.renderRemove()}
        </div>
      );

    if (status === "Running")
      return (
        <div className="col-12">
          {this.renderRunning()}
          {this.renderPreview()}
          {this.renderShareLinks()}
          {(isAdmin || canEditAds) && this.renderEdit()}
          {this.renderCancel()}
          {this.renderNotification()}
          {isAdmin && this.renderRemove()}
        </div>
      );

    if (status === "Scheduled") {
      return (
        <div className="col-12">
          {this.renderScheduled()}
          {this.renderPreview()}
          {this.renderShareLinks()}
          {(isAdmin || canEditAds) && this.renderEdit()}
          {this.renderCancel()}
          {this.renderNotification()}
          {isAdmin && this.renderRemove()}
        </div>
      );
    }
    if (status === "Waiting")
      return (
        <div className="col-12">
          {this.renderWaiting()}
          {this.renderPreview()}
          {this.renderNotification()}
          {(isAdmin || canEditAds) && this.renderEdit()}
          {isAdmin && this.renderRemove()}
        </div>
      );

    // Render default
    return (
      <>
        <div className="col-12">
          {this.renderPreview()}
          {this.renderNotification()}
          {(isAdmin || canEditAds) && this.renderEdit()}
          {this.renderCancel()}
          {isAdmin && this.renderRemove()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaigns.list,
  user: state.user
});
export default connect(mapStateToProps)(CampaignSummary);
