import React from "react";
import PropTypes from "prop-types";
import includes from "array-includes";
import { connect } from "react-redux";
import { withRouter, Route, Redirect } from "react-router-dom";
import { authActions } from "src/auth";
import { unregister } from "../../utils/register-service-worker";
import SignInPage from "../pages/sign-in";
import Dashboard from "../pages/dashboard";
import "./app.css";

class App extends React.Component {
  render() {
    if (
      includes(this.props.location.pathname, "/admin") ||
      includes(this.props.location.pathname, "/forms") ||
      includes(this.props.location.pathname, "/reset")
    ) {
      unregister();
      window.location.reload(true);
      return null;
    }
    const { authenticated, email, signOut } = this.props;
    if (email === "admin@adway.ai") {
      signOut();
      return null;
    }

    // Set ImBox login
    if (authenticated) {
      const _imbox = window._imbox || [];
      _imbox.push([
        "insertCard",
        "card",
        this.props.id,
        `Email: ${this.props.email}`
      ]);
    }

    return (
      <div className="app__container">
        <main className="app__content">
          {authenticated &&
            this.props.location.pathname === "/sign-in" && <Redirect to="/" />}
          {authenticated && <Route path="/" component={Dashboard} />}
          {!authenticated && <Route path="/sign-in" component={SignInPage} />}
          {!authenticated && <Redirect to="/sign-in" />}
        </main>
      </div>
    );
  }
}

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired
};

//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = state => state.auth.toJS();

const mapDispatchToProps = {
  signOut: authActions.signOut
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
