import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import CampaignNavigation from "./navigation";
import CampaignSummary from "./summary";
import CampaignPreview from "./preview";
import CampaignShare from "./share";
import CampaignChannels from "./channels";

class CampaignDetails extends Component {
  renderPlaceholder() {
    return <div className="col-5 text-white p-6 bg-adway scroll-y" />;
  }

  render() {
    return (
      <div
        className="col-5 text-white bg-adway0 scroll-y px-5"
        style={{ maxHeight: "100%" }}
        id="scroll-content"
      >
        <Route
          path="/:campaignType/:campaignID"
          component={CampaignNavigation}
        />
        <div className="row py-5">
          <Switch>
            <Route
              exact
              path="/:campaignType/:campaignID"
              component={CampaignSummary}
            />
            <Route
              path="/:campaignType/:campaignID/preview"
              component={CampaignPreview}
            />
            <Route
              path="/:campaignType/:campaignID/share"
              component={CampaignShare}
            />
            <Route
              path="/:campaignType/:campaignID/channels"
              component={CampaignChannels}
            />

            <Route
              path="/:campaignType/:campaignID/edit"
              component={CampaignPreview}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaigns.list,
  adData: state.adData
});

export default connect(mapStateToProps)(CampaignDetails);
