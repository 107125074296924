import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

class CampaignChannels extends Component {
  renderSocialLink(source, url, type, start_time, end_time, status) {
    const today = moment();
    const dateStart = moment(start_time);
    const dateEnd = end_time !== -1 ? moment(end_time) : 0;
    let adRunTime = dateEnd ? Math.ceil(dateEnd.diff(dateStart, "d", true)) : 0;

    let adCurrentProgress = Math.ceil(
      Math.abs(dateStart.diff(today, "d", true))
    );

    if (!dateEnd) adRunTime = 1;
    if (adCurrentProgress > adRunTime) {
      adCurrentProgress = adRunTime;
    }
    if (status === "Scheduled") {
      adCurrentProgress = 0;
    }

    return (
      <a href={url}>
        <button
          className="btn btn-block btn-sm btn-outline-secondary p-2 px-3 d-flex flex-row align-items-center text-left"
          style={{ marginTop: "7.2px" }}
        >
          <div className="pr-3">
            <ion-icon
              name={`logo-${source}`}
              style={{ marginLeft: "14.4px" }}
            />
            <ion-icon name="git-compare" style={{ marginLeft: "14.4px" }} />
          </div>
          <div>
            <strong style={{ textTransform: "capitalize" }}>{source}</strong>
            <br />
            <small>{type}</small>
          </div>
        </button>
      </a>
    );
  }

  renderJobSiteLink(source, type, runtime) {
    return (
      <button className="btn btn-block btn-sm btn-outline-secondary p-2 px-3 d-flex flex-row align-items-center text-left">
        <div className="pr-3">
          <ion-icon name="clipboard" style={{ marginLeft: "14.4px" }} />
          <ion-icon name="git-branch" style={{ marginLeft: "14.4px" }} />
        </div>
        <div>
          <strong>{source}</strong>
          <br />
          <small>{type}</small>
        </div>
      </button>
    );
  }

  render() {
    const { campaignID } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }

    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );

    const { status } = campaignData;

    return (
      <div className="col-12">
        {(campaignData.reach ||
          campaignData.instagramPosts ||
          campaignData.linkedInPosts) && (
          <p className="text-op-75 mb-5">
            Your job ad was published in the following social media networks.
          </p>
        )}

        {campaignData.reach &&
          campaignData.reach.posts &&
          campaignData.reach.posts.map(postData => {
            return this.renderSocialLink(
              "facebook",
              "//" + postData.url,
              postData.type,
              postData.start_time,
              postData.end_time,
              status
            );
          })}

        {campaignData.reach &&
          campaignData.reach.instagramPosts &&
          campaignData.reach.instagramPosts.map(postData => {
            return this.renderSocialLink(
              "instagram",
              postData.url,
              postData.type,
              postData.start_time,
              postData.end_time,
              status
            );
          })}

        {campaignData.reach &&
          campaignData.reach.linkedInPosts &&
          campaignData.reach.linkedInPosts.map(postData => {
            return this.renderSocialLink(
              "linkedin",
              postData.url,
              postData.type,
              postData.start_time,
              postData.end_time,
              status
            );
          })}

        {campaignData.jobSitePosts && (
          <p className="text-op-75 my-5">
            Your job ad was published on the following job boards.
          </p>
        )}

        {campaignData.jobSitePosts &&
          campaignData.jobSitePosts.posts &&
          campaignData.jobSitePosts.posts.map(postData => {
            return this.renderJobSiteLink(
              postData.source,
              postData.type,
              postData.runtime
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaigns.list
});
export default connect(mapStateToProps)(CampaignChannels);
