import React, { useState } from "react";
import { FormGroup } from "reactstrap";

import Dropzone from "react-dropzone";

import "./edit.css";

const ImageUploader = ({
  uploadHandler,
  generateHandler,
  canGenerateImage,
  imageUrl,
  text,
  platform
}) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [generateInProgress, setGenerateInProgress] = useState(false);

  const actionInProgress = uploadInProgress || generateInProgress;

  return (
    <FormGroup
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineheight: "17px",
          letterSpacing: "0.04em",
          color: "#6C757D",
          margin: "20px 0px"
        }}
      >
        {text}
      </span>
      <Dropzone
        noClick={actionInProgress}
        onDrop={async acceptedFiles => {
          setUploadInProgress(true);
          await uploadHandler(acceptedFiles, platform);
          setUploadInProgress(false);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={{
                width: "400px",
                height: "300px",
                background: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url("${imageUrl}")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                cursor: !actionInProgress && "pointer"
              }}
            >
              {actionInProgress && (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      actionInProgress &&
                      "linear-gradient(0deg, rgba(58, 66, 79, 0.7), rgba(58, 66, 79, 0.7))"
                  }}
                >
                  <span className="spinner" style={{ margin: "auto" }} />
                </div>
              )}
              <input {...getInputProps()} />
            </div>
          </section>
        )}
      </Dropzone>

      <div style={{ display: "flex" }}>
        <Dropzone
          noClick={actionInProgress}
          onDrop={async acceptedFiles => {
            setUploadInProgress(true);
            await uploadHandler(acceptedFiles, platform);
            setUploadInProgress(false);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <button
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                padding: "10px 16px",
                fontWeight: "bolder",
                marginRight: "6px",
                marginLeft: "6px"
              }}
              type="button"
              onClick={async e => {
                e.preventDefault();
              }}
              disabled={actionInProgress}
              className={`btn btn-sm btn-primary`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              Upload Image
              {uploadInProgress ? (
                <span className="spinner" style={{ marginLeft: "5px" }} />
              ) : (
                <svg
                  style={{ paddingLeft: "5px" }}
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M18.9 9.84141C19.0441 9.46524 19.125 9.05391 19.125 8.625C19.125 6.76172 17.6133 5.25 15.75 5.25C15.0574 5.25 14.4105 5.46094 13.8762 5.81953C12.9023 4.13203 11.0848 3 9 3C5.89219 3 3.375 5.51719 3.375 8.625C3.375 8.71992 3.37852 8.81484 3.38203 8.90977C1.41328 9.60234 0 11.4797 0 13.6875C0 16.4824 2.26758 18.75 5.0625 18.75H18C20.4855 18.75 22.5 16.7355 22.5 14.25C22.5 12.0738 20.9531 10.2563 18.9 9.84141ZM13.8305 12H11.5313V15.9375C11.5313 16.2469 11.2781 16.5 10.9688 16.5H9.28125C8.97188 16.5 8.71875 16.2469 8.71875 15.9375V12H6.41953C5.9168 12 5.66719 11.3953 6.02227 11.0402L9.72774 7.33477C9.9457 7.1168 10.3043 7.1168 10.5223 7.33477L14.2277 11.0402C14.5828 11.3953 14.3297 12 13.8305 12Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="22"
                        height="22"
                        fill="white"
                        transform="matrix(1 0 0 -1 0 22)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
          )}
        </Dropzone>
        {canGenerateImage && (
          <button
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              padding: "10px 16px",
              fontWeight: "bolder",
              marginRight: "6px",
              marginLeft: "6px"
            }}
            type="button"
            disabled={actionInProgress}
            className={`btn btn-sm btn-primary`}
            onClick={async e => {
              e.preventDefault();
              if (!actionInProgress) {
                setGenerateInProgress(true);
                await generateHandler(platform);
                setGenerateInProgress(false);
              }
            }}
          >
            Generate Image
            {generateInProgress ? (
              <div style={{ marginLeft: "5px" }}>
                <span className="spinner" />
              </div>
            ) : (
              <svg
                style={{ marginLeft: "5px" }}
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.796 6.90799L14.234 5.20999C14.1306 5.10812 13.9912 5.05103 13.846 5.05103C13.7008 5.05103 13.5615 5.10812 13.458 5.20999L11.894 6.90799C11.843 6.95848 11.8025 7.0186 11.7748 7.08486C11.7472 7.15111 11.7329 7.22219 11.7329 7.29399C11.7329 7.36578 11.7472 7.43687 11.7748 7.50312C11.8025 7.56938 11.843 7.62949 11.894 7.67999H13.188C12.946 8.58935 12.4681 9.41868 11.8028 10.0842C11.1375 10.7497 10.3083 11.2277 9.39902 11.47C8.33639 11.748 7.21412 11.6939 6.18313 11.3151C5.15214 10.9363 4.26187 10.2508 3.63202 9.35099L2.54102 10.102C3.32692 11.2248 4.43784 12.08 5.72434 12.5525C7.01084 13.025 8.4112 13.0922 9.73702 12.745C10.9294 12.4288 12.0107 11.788 12.8604 10.8937C13.7102 9.99948 14.2951 8.88695 14.55 7.67999H15.795C15.846 7.62949 15.8865 7.56941 15.9141 7.50319C15.9418 7.43698 15.9561 7.36595 15.9562 7.2942C15.9563 7.22244 15.9422 7.15137 15.9147 7.08509C15.8872 7.01881 15.8469 6.95862 15.796 6.90799Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.47501 6.02102C4.52604 5.97052 4.56656 5.91041 4.59421 5.84415C4.62186 5.77789 4.6361 5.70681 4.6361 5.63502C4.6361 5.56322 4.62186 5.49214 4.59421 5.42588C4.56656 5.35963 4.52604 5.29951 4.47501 5.24902H3.01801C3.26588 4.35114 3.74434 3.53374 4.40588 2.87799C5.06741 2.22225 5.88899 1.75099 6.78901 1.51102C7.85167 1.23273 8.9741 1.28686 10.0051 1.66609C11.036 2.04533 11.926 2.73145 12.555 3.63202L13.647 2.88002C12.8609 1.7566 11.7495 0.900891 10.4625 0.428018C9.17546 -0.0448553 7.77447 -0.11221 6.448 0.235016C5.26481 0.549726 4.19105 1.18403 3.3444 2.06844C2.49774 2.95284 1.91084 4.05322 1.64801 5.24902H0.196005C0.144969 5.29951 0.104452 5.35963 0.0768011 5.42588C0.0491497 5.49214 0.0349121 5.56322 0.0349121 5.63502C0.0349121 5.70681 0.0491497 5.77789 0.0768011 5.84415C0.104452 5.91041 0.144969 5.97052 0.196005 6.02102L1.83401 7.65802C1.93746 7.75988 2.07682 7.81698 2.222 7.81698C2.36719 7.81698 2.50655 7.75988 2.61 7.65802L4.47501 6.02102Z"
                  fill="white"
                />
              </svg>
            )}
          </button>
        )}
      </div>
    </FormGroup>
  );
};

export default ImageUploader;
