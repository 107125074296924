import React, { Component } from "react";
import { connect } from "react-redux";

import { orgActions } from "../../../organization";

class CustomerMessage extends Component {
  render() {
    const { organization, user } = this.props;

    if (!user) return null;

    const selectedOrg = user.get("orgData").get("current");

    if (!organization && selectedOrg) {
      this.props.fetchOrganization(selectedOrg);
      return null;
    }

    // Make sure we have the currently selected organization loaded
    const loadedOrg = organization.id;
    if (selectedOrg !== loadedOrg && selectedOrg) {
      this.props.fetchOrganization(selectedOrg);
      return null;
    }

    if (
      !organization.notification ||
      !organization.notification.enabled ||
      !organization.notification.message
    )
      return null;

    return (
      <div
        className=" bg-faded d-flex flex-row align-items-center justify-content-center"
        style={{
          width: "100%",
          padding: "2.6px 7.6px 7.6px 7.6px",
          maxHeight: "34px"
        }}
      >
        <ion-icon
          name="information-circle-outline"
          style={{ marginRight: "8px", padding: "0", fontSize: "20px" }}
        />
        <p
          className="text-muted py-1"
          style={{
            margin: "5px 0 0 0"
          }}
        >
          {organization.notification.message}
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
});

const mapDispatchToProps = {
  fetchOrganization: orgActions.loadOrg
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMessage);
