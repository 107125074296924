import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip
} from "reactstrap";
import { withRouter, NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { authActions } from "../../../auth";
import { userActions } from "../../../user";
import { campaignActions } from "../../../campaigns";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTypeFilter: null
    };
  }
  toggleTypeFilter = newFilter => {
    const { currentTypeFilter } = this.state;
    const {
      setCampaignTypeFilter,
      resetCampaignFiltersAndSearch,
      loadCampaigns
    } = this.props;

    let futureFilter = null;
    // Check if it's a toggle
    if (newFilter !== currentTypeFilter) {
      futureFilter = newFilter;
    }

    setCampaignTypeFilter(futureFilter);
    resetCampaignFiltersAndSearch();
    loadCampaigns();
    this.setState({ currentTypeFilter: futureFilter });
  };
  generateOrgList = () => {
    const {
      user,
      history,
      setCurrentOrganization,
      resetCampaigns
    } = this.props;
    if (typeof user === "undefined" || user === null) {
      return null;
    }
    const orgList = user
      .get("orgData")
      .get("list")
      .toJS();
    const orgIDs = Object.keys(orgList);
    orgIDs.sort((a, b) => {
      const aName = orgList[a];
      const bName = orgList[b];
      if (aName > bName) {
        return 1;
      }
      if (aName < bName) {
        return -1;
      }
      return 0;
    });
    return orgIDs.map(orgID => (
      <DropdownItem
        className="small"
        key={`sidebar-dropdown-${orgID}`}
        onClick={() => {
          resetCampaigns();
          setCurrentOrganization(orgID);
          history.push("/");
        }}
      >
        {orgList[orgID]}
      </DropdownItem>
    ));
  };
  render() {
    const { user } = this.props;
    if (user === null) {
      return null;
    }
    return (
      <div className="d-flex flex-column sidebar">
        <Link
          to="/jobs"
          style={{ cursor: "pointer" }}
          className="text-center"
          onClick={() => {
            this.toggleTypeFilter(null);
          }}
        >
          <img src="/images/adway.jpg" width="100%" alt="Adway Logo" />
        </Link>
        <NavLink
          to="/insights"
          className="tab-item tooltips p-3 text-center text-white"
          title="Insights"
        >
          <ion-icon
            name="pulse"
            style={{ fontSize: "2em" }}
            id="insights-icon"
          />
          <UncontrolledTooltip placement="right" target="insights-icon">
            Insights
          </UncontrolledTooltip>
        </NavLink>
        <NavLink
          to="/jobs"
          style={{ cursor: "pointer" }}
          className="tab-item tooltips p-3 text-center text-white"
          activeClassName="bg-adway"
          onClick={() => {
            this.toggleTypeFilter("job");
          }}
        >
          <ion-icon
            id="job-filter-icon"
            name="megaphone"
            style={{ fontSize: "2em" }}
          />
          <UncontrolledTooltip placement="right" target="job-filter-icon">
            Jobs
          </UncontrolledTooltip>
        </NavLink>
        <NavLink
          to="/branding"
          style={{ cursor: "pointer" }}
          className="tab-item tooltips p-3 text-center text-white"
          activeClassName="bg-adway"
          onClick={() => {
            this.toggleTypeFilter("branding");
          }}
        >
          <ion-icon
            id="branding-filter-icon"
            name="heart"
            style={{ fontSize: "2em" }}
          />
          <UncontrolledTooltip placement="right" target="branding-filter-icon">
            Branding
          </UncontrolledTooltip>
        </NavLink>
        <UncontrolledDropdown
          direction="up"
          className="tab-item mt-auto text-center text-white"
        >
          <DropdownToggle
            className="tab-item text-center"
            nav
            style={{ color: "white", padding: "16px" }}
          >
            <ion-icon name="settings" style={{ fontSize: "2em" }} />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>Change company</DropdownItem>
            {this.generateOrgList()}
            <DropdownItem divider />
            <DropdownItem header>{this.props.auth.email}</DropdownItem>
            <DropdownItem onClick={this.props.signOut} className="small">
              Sign Out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}

const mapDispatchToActions = {
  signOut: authActions.signOut,
  setCurrentOrganization: userActions.setCurrentOrganization,
  setCampaignTypeFilter: campaignActions.setTypeFilter,
  resetCampaignFiltersAndSearch: campaignActions.resetFilterAndSearch,
  resetCampaigns: campaignActions.resetCampaigns,
  loadCampaigns: campaignActions.loadCampaigns
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToActions
  )(Sidebar)
);
