import {
  LOAD_USER_SUCCESS,
  SET_CURRENT_ORGANIZATION,
  RESET_USER
} from "./action-types";
import { tokenUtils, authActions } from "../auth";

export function loadUser() {
  return (dispatch, getState) => {
    const { auth } = getState();
    tokenUtils
      .tokenTest()
      .then(user => {
        const orgPermissions = user.permissions
          ? user.permissions.organizations
            ? user.permissions.organizations
            : {}
          : {};

        const userOrgIDs = [];
        Object.keys(orgPermissions).forEach(orgID => {
          if (orgPermissions[orgID] && orgPermissions[orgID].ads_read) {
            userOrgIDs.push(orgID);
          }
        });
        const orgBeforeReload = localStorage.getItem(
          `current_organization_${auth.id}`
        );
        const currentOrg =
          userOrgIDs.indexOf(orgBeforeReload) !== -1 ? orgBeforeReload : null;
        const orgData = { current: currentOrg || userOrgIDs[0], list: {} };
        user["canEditAds"] = !!orgPermissions[currentOrg || userOrgIDs[0]]
          .ads_editing;

        user["isAdmin"] = (() => {
          if (
            !!user.permissions &&
            !!user.permissions.admin &&
            !!user.permissions.admin.all &&
            !!user.permissions.admin.all.all
          ) {
            return true;
          } else if (!!user.permissions && user.permissions.admin === true) {
            return true;
          } else {
            return false;
          }
        })();

        tokenUtils
          .makeRequest("/organizations/names", { id: userOrgIDs.join(",") })
          .then(response => {
            response.forEach(respOrgData => {
              orgData.list[respOrgData.id] = respOrgData.name;
            });
            user["orgData"] = orgData;
            dispatch(loadUserSuccess(user));
          });
      })
      .catch(err => {
        console.warn(`Signing out due to token rejection.`, err);
        authActions.signOut()(dispatch);
      });
  };
}

export function loadUserSuccess(payload) {
  return {
    type: LOAD_USER_SUCCESS,
    payload: payload
  };
}

export function setCurrentOrganization(orgID) {
  return (dispatch, getState) => {
    const { auth } = getState();
    localStorage.setItem(`current_organization_${auth.id}`, orgID);
    dispatch(setCurrentOrganizationState(orgID));
  };
}

function setCurrentOrganizationState(orgID) {
  return {
    type: SET_CURRENT_ORGANIZATION,
    payload: orgID
  };
}

export function resetUser() {
  return {
    type: RESET_USER,
    payload: null
  };
}
