import React, { Component } from "react";
import { Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Campaign from "../campaign-card";
import ProgressCircle from "../progress-circle";
import "./listing.css";

class Listing extends Component {
  constructor(props) {
    super(props);
    this.listRenderCounter = 0;
  }
  renderList() {
    const { list, isLoading } = this.props;
    // Increase ID counter
    this.listRenderCounter += 1;
    // Check for empty data
    if (list === null) {
      return <ProgressCircle />;
    }
    if (Object.keys(list).length === 0) {
      return (
        <h4 className="text-muted text-center mt-5">
          No job ads published yet, stay tuned...
        </h4>
      );
    }

    const renderListItems = list.map(item => {
      return <Campaign data={item} key={item.id} />;
    });

    if (isLoading) {
      renderListItems.push(<ProgressCircle key="progress" />);
    }

    return renderListItems;
  }
  render() {
    return (
      <Col className="listing__container p-0" size={12}>
        <ul className="list-unstyled">{this.renderList()}</ul>
      </Col>
    );
  }
}

export default withRouter(Listing);
