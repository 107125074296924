import React, { Component } from "react";
import { connect } from "react-redux";
import { orgActions } from "../../../organization";
import CustomerMessage from "../customer-message";
import { PieChart, Pie, Cell, Label } from "recharts";
import Color from "color";
import "./insights.css";

const mergeDuplicateAudiences = audienceList => {
  // This function is specifically for merging audiences for Peab
  const tjänstemän1 = audienceList.find(
    audience => audience.name === "Peab AB R Tjänstemän 1"
  );
  const tjänstemän2 = audienceList.find(
    audience => audience.name === "Peab AB R Tjänstemän 2"
  );

  const arbetare1 = audienceList.find(
    audience => audience.name === "Peab AB R Arbetare 1"
  );

  const arbetare2 = audienceList.find(
    audience => audience.name === "Peab AB R Arbetare 2"
  );

  const arbetare3 = audienceList.find(
    audience => audience.name === "Peab AB R Arbetare 3"
  );

  const toimihenkilöt1 = audienceList.find(
    audience => audience.name === "Peab OY R Toimihenkilöt 1"
  );
  const toimihenkilöt2 = audienceList.find(
    audience => audience.name === "Peab OY R Toimihenkilöt 2"
  );

  const audienceListWithMergedAudiences = audienceList.filter(
    audience =>
      audience.name !== "Peab AB R Tjänstemän 1" &&
      audience.name !== "Peab AB R Tjänstemän 2" &&
      audience.name !== "Peab AB R Arbetare 1" &&
      audience.name !== "Peab AB R Arbetare 2" &&
      audience.name !== "Peab AB R Arbetare 3" &&
      audience.name !== "Peab OY R Toimihenkilöt 1" &&
      audience.name !== "Peab OY R Toimihenkilöt 2"
  );

  if (tjänstemän1 && tjänstemän2) {
    const tjänstemän = {
      name: "Peab AB R Tjänstemän",
      id: tjänstemän1.id,
      category: "Tjänstemän",
      approximate_count:
        tjänstemän1.approximate_count + tjänstemän2.approximate_count,
      type: "fb",
      is_full_count: false
    };
    audienceListWithMergedAudiences.push(tjänstemän);
  }

  if (arbetare1 && arbetare2 && arbetare3) {
    const arbetare = {
      name: "Peab AB R Arbetare",
      id: arbetare1.id,
      category: "Arbetare",
      approximate_count:
        arbetare1.approximate_count +
        arbetare2.approximate_count +
        arbetare3.approximate_count,
      type: "fb",
      is_full_count: false
    };
    audienceListWithMergedAudiences.push(arbetare);
  }

  if (toimihenkilöt1 && toimihenkilöt2) {
    const toimihenkilöt = {
      name: "Peab OY R Toimihenkilöt",
      id: toimihenkilöt1.id,
      category: "Toimihenkilöt",
      approximate_count:
        toimihenkilöt1.approximate_count + toimihenkilöt2.approximate_count,
      type: "fb",
      is_full_count: false
    };
    audienceListWithMergedAudiences.push(toimihenkilöt);
  }

  return audienceListWithMergedAudiences;
};

const separateThousands = number => {
  if (!number) return number;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const placeholderData = [
  {
    name: "",
    value: 33,
    color: "#555555"
  },
  {
    name: "",
    value: 33,
    color: "#333333"
  },
  {
    name: "",
    value: 33,
    color: "#444444"
  }
];

const getColor = id => {
  const baseColors = [
    "#8969ff",
    "#ff47bd",
    "#fa446b",
    "#720094",
    "#2D0085",
    "#180046"
  ];
  const colorList = [...baseColors];
  baseColors.forEach(color => {
    colorList.push(
      Color(color)
        .lighten(0.15)
        .toString()
    );
  });
  baseColors.forEach(color => {
    colorList.push(
      Color(color)
        .lighten(0.25)
        .toString()
    );
  });

  return colorList[id];
};

class Insights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAudiencePopup: true
    };
  }

  dismissAudiencePopup = () => {
    this.setState({ showAudiencePopup: false });
  };

  render() {
    const placeholder = <div className="container-fluid bg-adway1" />;

    const { user, organization, fetchOrganization } = this.props;
    if (!user) {
      return placeholder;
    }

    const currentOrg = user.get("orgData").get("current");
    if (!organization || organization.id !== currentOrg) {
      fetchOrganization(currentOrg);
      return placeholder;
    }

    const orgData = organization;

    const { audiences, week_audiences_delta } = orgData;

    const currentReach = orgData.reach;
    const deltaReach = orgData.week_reach_delta;

    if (!currentReach) return placeholder;

    const categoryData = [];
    let indexCounter = 0;

    if (audiences && audiences.list) {
      const audienceList = mergeDuplicateAudiences(audiences.list);
      audienceList.forEach(audienceData => {
        if (!audienceData.category) return;

        const color = getColor(indexCounter);
        categoryData.push({
          color,
          value: audienceData.approximate_count,
          name: audienceData.category
        });

        indexCounter += 1;
      });
    }

    const renderLabel = () => (
      <foreignObject x="90" y="143" width="200" height="100">
        <h1 className="mb-0">{separateThousands(audiences.count)}</h1>
        <p className="text-op-75">Audience size</p>
        {week_audiences_delta.count > 0 && (
          <>
            <span
              className="badge badge-success"
              style={{ marginLeft: "14.4px" }}
            >{`+${separateThousands(week_audiences_delta.count)}`}</span>
            <small className="text-op-75" style={{ marginLeft: "5px" }}>
              Since last week
            </small>
          </>
        )}
      </foreignObject>
    );

    const renderPlaceholderLabel = () => (
      <foreignObject x="90" y="143" width="200" height="100">
        <p
          className="text-op-75"
          style={{ marginLeft: "5px", marginTop: "7px" }}
        >
          Here you will see the sizes of your audiences once your organization
          gets started with retargeting
        </p>
      </foreignObject>
    );

    return (
      <div className="container-fluid bg-adway1">
        <div className="row h-100 align-self-md-center">
          <CustomerMessage />
          <div className="col-12 p-5 text-center text-white">
            <h3>Insights</h3>

            <p className="text-op-75 my-3">
              Real time data of your current audience, ads and performance.
            </p>

            <div className="row mt-3">
              <div className="col-3 text-left py-5 slideLeft">
                <h4>{separateThousands(currentReach.impressions)}</h4>
                <p className="mb-0 text-op-75">Impressions</p>
                {deltaReach && (
                  <>
                    <span className="badge badge-success">
                      {`+${separateThousands(deltaReach.impressions)}`}
                    </span>{" "}
                    <small className="text-op-75">During last week</small>
                  </>
                )}
                <hr className="border-0 my-5" />
                <h4>{separateThousands(currentReach.link_clicks)}</h4>
                <p className="mb-0 text-op-75">Clicked</p>
                {deltaReach && (
                  <>
                    <span className="badge badge-success">
                      {`+${separateThousands(deltaReach.link_clicks)}`}
                    </span>{" "}
                    <small className="text-op-75">During last week</small>
                  </>
                )}
              </div>
              <div className="col-6 text-center pt-5 pb-3 px-4">
                {categoryData.length === 0 && (
                  <PieChart width={386} height={386}>
                    <Pie
                      data={placeholderData}
                      innerRadius={145}
                      outerRadius={190}
                      paddingAngle={0}
                      stroke="none"
                    >
                      {placeholderData.map((data, index) => (
                        <Cell fill={placeholderData[index].color} />
                      ))}
                      <Label content={renderPlaceholderLabel} />
                    </Pie>
                  </PieChart>
                )}
                {categoryData.length > 0 && (
                  <PieChart width={386} height={386}>
                    <Pie
                      data={categoryData}
                      innerRadius={145}
                      outerRadius={190}
                      paddingAngle={0}
                      stroke="none"
                    >
                      {categoryData.map((data, index) => (
                        <Cell fill={categoryData[index].color} />
                      ))}
                      <Label content={renderLabel} />
                    </Pie>
                  </PieChart>
                )}
              </div>
              <div className="col-3 text-right py-5 slideRight">
                <h4>{separateThousands(currentReach.reactions)}</h4>
                <p className="mb-0 text-op-75">Reactions</p>
                {deltaReach && (
                  <>
                    <span className="badge badge-success">
                      {`+${separateThousands(deltaReach.reactions)}`}
                    </span>{" "}
                    <small className="text-op-75">During last week</small>
                  </>
                )}
                <hr className="border-0 my-5" />
                <h4>{separateThousands(currentReach.comments)}</h4>
                <p className="mb-0 text-op-75">Comments</p>
                {deltaReach && (
                  <>
                    <span className="badge badge-success">
                      {`+${separateThousands(deltaReach.comments)}`}
                    </span>{" "}
                    <small className="text-op-75">During last week</small>
                  </>
                )}
                <hr className="border-0 my-5" />
                <h4>{separateThousands(currentReach.shares)}</h4>
                <p className="mb-0 text-op-75">Shares</p>
                {deltaReach && (
                  <>
                    <span className="badge badge-success">
                      {`+${separateThousands(deltaReach.shares)}`}
                    </span>{" "}
                    <small className="text-op-75">During last week</small>
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col col-5">
                {categoryData.map(category => {
                  return (
                    <div
                      className="badge badge-success slideDown"
                      style={{
                        backgroundColor: category.color,
                        marginRight: "5px"
                      }}
                    >
                      {category.name}
                    </div>
                  );
                })}

                <hr className="my-5 mx-auto w-25 center" />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col col-2 text-center slideDown">
                <h2>{separateThousands(orgData.running_ads)}</h2>
                <p className="mb-0 text-op-75">Running ads</p>
              </div>
              <div className="col col-2 text-center slideDown">
                <h2>{separateThousands(orgData.scheduled_ads)}</h2>
                <p className="mb-0 text-op-75">Scheduled ads</p>
              </div>
            </div>

            {this.state.showAudiencePopup &&
              week_audiences_delta &&
              week_audiences_delta.count > 0 && (
                <div
                  onClick={this.dismissAudiencePopup}
                  className="alert alert-success text-left d-flex flex-row align-items-center slideUp"
                  style={{
                    margin: "auto auto",
                    width: "480px",
                    position: "absolute",
                    bottom: "25px",
                    left: "50%",
                    marginLeft: "-240px"
                  }}
                >
                  <h2 className="my-0 mr-3">
                    <ion-icon name="ribbon" style={{ marginLeft: "14.4px" }} />
                  </h2>
                  <div>
                    <strong>Congratulations</strong>
                    <br />
                    <small>
                      Your audience size grew with{" "}
                      <strong>{`+${separateThousands(
                        week_audiences_delta.count
                      )} `}</strong>
                      people since last week.
                    </small>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
});

const mapDispatchToProps = {
  fetchOrganization: orgActions.loadOrg
};

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
