import React, { Component } from "react";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Switch from "react-switch";
import { tokenUtils } from "../../../auth";
import ImageUploader from "./ImageUploader";
import { adDataActions } from "../../../adData";
import { notificationActions } from "../../../notifications";
import { campaignActions } from "../../../campaigns";

import "./edit.css";

const MAX_AD_BODY_SYMBOLS = 600;

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addOverlay: false,
      errorMessage: null,
      pendingServerReply: false
    };
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onRegenerateClick = this.onRegenerateClick.bind(this);
    this.campaignHasInstagramAds = this.campaignHasInstagramAds.bind(this);
    this.campaignHasLinkedInAds = this.campaignHasLinkedInAds.bind(this);
    this.campaignDataCache = {};
  }

  /**
   * Get campaign data by campaign ID and cache it
   *
   * @param {string} campaignId
   */
  getCampaignData(campaignId) {
    const key = JSON.stringify({ campaignId, campaigns: this.props.campaigns });

    if (!this.campaignDataCache[key]) {
      this.campaignDataCache[key] = this.props.campaigns
        ? this.props.campaigns.find(
            campaignData => campaignData.id === campaignId
          )
        : {};
    }

    return this.campaignDataCache[key];
  }

  campaignHasInstagramAds(campaignId) {
    const campaignData = this.getCampaignData(campaignId);

    return (
      (!!campaignData.tracking_data &&
        (!!campaignData.tracking_data.ads &&
          !!campaignData.tracking_data.ads.in &&
          campaignData.tracking_data.ads.in.length > 0)) ||
      (campaignData.tracking_data.ad_data &&
        campaignData.tracking_data.ad_data.adImageIn)
    );
  }

  campaignHasLinkedInAds(campaignId) {
    const campaignData = this.getCampaignData(campaignId);

    return (
      (!!campaignData.tracking_data &&
        !!campaignData.tracking_data.ads &&
        (!!campaignData.tracking_data.ads.ln &&
          campaignData.tracking_data.ads.ln.length > 0)) ||
      (campaignData.tracking_data.ad_data &&
        campaignData.tracking_data.ad_data.adImageLinkedIn)
    );
  }

  initializeAdData(props) {
    const { campaignID } = props.match.params;

    const campaignData = this.getCampaignData(campaignID);

    if (campaignData) {
      const adData =
        campaignData.tracking_data && campaignData.tracking_data.ad_data
          ? campaignData.tracking_data.ad_data
          : {};

      if (
        this.campaignHasInstagramAds(campaignID) &&
        !(adData.adBodyIn && adData.adBodyIn.length)
      ) {
        adData.adBodyIn = adData.adBody;
      }

      if (
        this.campaignHasLinkedInAds(campaignID) &&
        !(adData.adBodyLn && adData.adBodyLn.length)
      ) {
        adData.adBodyLn = adData.adBody.substring(0, MAX_AD_BODY_SYMBOLS);
      }

      props.setAdData(adData);
    }

    return;
  }
  onRegenerateClick(platform) {
    const { campaignID } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }
    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );
    const {
      adData: { adBody, adBodyIn, adBodyLn, adDescription, ...adData }
    } = this.props;

    // remove ad body, otherwise request could be too large
    // check that this works on update

    if (!campaignData) return null;

    const trackingId = campaignData.tracking_id;
    const adGenId = campaignData.adgen_id;
    const orgId = campaignData.organization_id;

    const freshAdData = JSON.stringify(adData);

    return tokenUtils
      .makeRequest(
        `/campaignmanager/generateImage`,
        {
          action_object_id: trackingId,
          org_id: orgId,
          adgen_id: adGenId,
          addOverlay: this.getCampaignData(campaignID).canAddOverlay,
          platforms: JSON.stringify({ [platform]: true }),
          freshAdData
        },
        false,
        "POST",
        true
      )
      .then(newImages => {
        const updatedAdData = {
          ...adData,
          adBody,
          adBodyIn,
          adBodyLn,
          adDescription
        };

        if (newImages.facebook) {
          updatedAdData.adImage = newImages.facebook.hash;
          updatedAdData.adImageURL = newImages.facebook.url;
        }

        if (newImages.linkedIn) {
          updatedAdData.adImageLinkedIn = newImages.linkedIn.url;
        }

        if (newImages.instagram) {
          updatedAdData.adImageIn = newImages.instagram.hash;
          updatedAdData.adImageInUrl = newImages.instagram.url; // We currently do not save adImageInUrl in adData
        }

        this.props.setAdData(updatedAdData);
        this.setState({
          errorMessage: null
        });
      })
      .catch(reason => {
        this.setState({ errorMessage: reason.message });
      });
  }

  onUpdateClick() {
    const { campaignID } = this.props.match.params;

    if (!this.props.campaigns) {
      return null;
    }
    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );
    if (!campaignData) return null;

    this.setState({ pendingServerReply: true });

    const trackingId = campaignData.tracking_id;
    const adGenId = campaignData.adgen_id;
    const orgId = campaignData.organization_id;

    const { adData } = this.props;

    if (!adData) return null;

    const freshAdData = JSON.stringify(adData);

    const formData = new FormData();

    formData.append("org_id", orgId);
    formData.append("action_object_id", trackingId);
    formData.append("adgen_id", adGenId);
    formData.append("freshAdData", freshAdData);

    return tokenUtils
      .uploadFormData(
        `/campaignmanager/edit?adgen_id=${adGenId}`,
        formData,
        false,
        "POST"
      )
      .then(() => {
        // Redirect user into main page
        this.setState({ pendingServerReply: false });
        this.props.setAdUpdateCompleted();
        this.props.history.push(`/jobs/${campaignID}/preview`);
      });
  }

  componentDidMount() {
    this.initializeAdData(this.props);
  }

  handleChange(property, event) {
    const adData = this.props.adData;
    const value = event.currentTarget.value;

    if (
      (property === "adBody" ||
        property === "adBodyLn" ||
        property === "adBodyIn") &&
      value.length > MAX_AD_BODY_SYMBOLS
    ) {
      return;
    }

    adData[property] = value;
    this.props.setAdData(adData);
  }

  handleImageUpload(acceptedFiles, type) {
    if (!this.props.campaigns) {
      return null;
    }
    const { campaignID } = this.props.match.params;
    const campaignData = this.props.campaigns.find(
      campaignData => campaignData.id === campaignID
    );
    const file = acceptedFiles[0];

    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    return new Promise(
      (resolve, reject) =>
        (img.onload = async () => {
          const ratio = img.width / img.height;

          if (type === "linkedIn" && ratio < 1.3 && ratio > 2) {
            this.setState({
              errorMessage:
                "Aspect ratio of 16:9 is required for LinkedIn images."
            });
            return null;
          } else if (ratio < 1 && ratio > 16 / 9) {
            this.setState({
              errorMessage:
                "Aspect ratio between 1:1 and 16:9 is required for Facebook/Instagram images."
            });
            return null;
          }

          const orgId = campaignData.organization_id;

          if (!this.props.campaigns) {
            return null;
          }
          if (!campaignData) return null;

          const trackingId = campaignData.tracking_id;
          const adGenId = campaignData.adgen_id;

          const { addOverlay, height } = this.state;

          const { adData } = this.props;

          const freshAdData = JSON.stringify(adData);

          const formData = new FormData();

          const platform = type === "linkedIn" ? "linkedIn" : "facebook";

          formData.append("org_id", orgId);
          formData.append("image", file);
          formData.append("action_object_id", trackingId);
          formData.append("adgen_id", adGenId);
          formData.append("freshAdData", freshAdData);
          formData.append("addOverlay", addOverlay);
          formData.append("height", height);
          formData.append("platform", platform);

          return tokenUtils
            .uploadFormData(
              `/campaignmanager/uploadImage?adgen_id=${adGenId}`,
              formData,
              false,
              "POST",
              true
            )
            .then(newImage => {
              this.setState({ errorMessage: null });

              if (type === "instagram") {
                this.props.setAdData({
                  ...adData,
                  adImageIn: newImage.hash,
                  adImageInUrl: newImage.url
                });
              } else if (type === "linkedIn") {
                this.props.setAdData({
                  ...adData,
                  adImageLinkedIn: newImage.url
                });
              } else {
                this.props.setAdData({
                  ...adData,
                  adImage: newImage.hash,
                  adImageURL: newImage.url
                });
              }
              resolve();
            })
            .catch(reason => {
              this.setState({ errorMessage: reason.message });
              reject();
            });
        })
    );
  }

  render() {
    const { campaignID } = this.props.match.params;
    const { adData, user } = this.props;

    const isAdmin = user.get("isAdmin");

    return (
      <Col
        style={{
          width: "100%x",
          background: "rgba(15, 0, 61, 0.03)",
          borderRadius: "5px"
        }}
      >
        <div style={{ padding: "25px" }}>
          <Link to={`/jobs/${campaignID}/`}>
            <h6
              style={{
                justifyContent: "flex-end",
                display: "flex",
                color: "#999"
              }}
            >
              Close
            </h6>
          </Link>
          <Form>
            <h4 style={{ color: "black" }}>Update Ad</h4>

            <span
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "14px",
                lineheight: "14px",
                letterSpacing: "0.04em",
                color: "#6C757D"
              }}
            >
              Update your job ad in social media.
            </span>

            {/* Update the ad title */}
            <FormGroup>
              <Label sm={8} for="adTitle">
                Ad Title
              </Label>
              <Input
                style={{ height: "50px", backgroundColor: "white" }}
                type="text"
                name="adTitle"
                id="adTitle"
                value={adData.adTitle}
                onChange={e => this.handleChange("adTitle", e)}
              />
            </FormGroup>

            {adData.originalTitle && (
              <FormGroup>
                <Label sm={8} for="role">
                  Job Title
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="text"
                  name="role"
                  id="role"
                  value={adData.originalTitle}
                  onChange={e => this.handleChange("originalTitle", e)}
                />
              </FormGroup>
            )}

            {adData.role && (
              <FormGroup>
                <Label sm={8} for="role">
                  Role
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="text"
                  name="role"
                  id="role"
                  value={adData.role}
                  onChange={e => this.handleChange("role", e)}
                />
              </FormGroup>
            )}
            {isAdmin && (
              <FormGroup>
                <Label sm={8} for="adCategory">
                  Category
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="text"
                  name="adCategory"
                  id="adCategory"
                  value={adData.adCategory}
                  onChange={e => this.handleChange("adCategory", e)}
                />
              </FormGroup>
            )}
            {isAdmin && (
              <FormGroup>
                <Label sm={8} for="adLocation">
                  Ad Location
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="text"
                  name="adLocation"
                  id="adLocation"
                  value={adData.adLocation}
                  onChange={e => this.handleChange("adLocation", e)}
                />
              </FormGroup>
            )}

            {adData.employer && (
              <FormGroup>
                <Label sm={8} for="employer">
                  Employer
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="text"
                  name="employer"
                  id="employer"
                  value={adData.employer}
                  onChange={e => this.handleChange("employer", e)}
                />
              </FormGroup>
            )}

            {isAdmin && (
              <FormGroup>
                <Label sm={8} for="adLink">
                  Ad Link
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="link"
                  name="adLink"
                  id="adLink"
                  value={adData.adLink}
                  onChange={e => this.handleChange("adLink", e)}
                />
              </FormGroup>
            )}

            {isAdmin && (
              <FormGroup>
                <Label sm={8} for="adLinkDesc">
                  Ad Link Description
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="text"
                  name="adLinkDesc"
                  id="adLinkDesc"
                  value={adData.adLinkDesc}
                  onChange={e => this.handleChange("adLinkDesc", e)}
                />
              </FormGroup>
            )}

            {isAdmin && (
              <FormGroup>
                <Label sm={8} for="adLink">
                  Ad Link Caption
                </Label>
                <Input
                  style={{ height: "50px", backgroundColor: "white" }}
                  type="text"
                  name="adLinkCaption"
                  id="adLinkCaption"
                  value={adData.adLinkCaption}
                  onChange={e => this.handleChange("adLinkCaption", e)}
                />
              </FormGroup>
            )}
            <br />

            <h5 style={{ color: "black", fontWeight: "regular" }}>Ad Copy</h5>
            <span
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17px",
                letterSpacing: "0.04em",
                color: "#B5BABE"
              }}
            >
              Customize your ad copy. Facebook copy will be used as a default
              copy if no customization has been provided
            </span>
            <FormGroup>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineheight: "17px",
                    letterSpacing: "0.04em",
                    color: "#6C757D",
                    margin: "20px 0px"
                  }}
                >
                  Facebook Copy
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineheight: "17px",
                    letterSpacing: "0.04em",
                    color: "#6C757D",
                    margin: "20px",
                    padding: "0px"
                  }}
                >
                  ({adData.adBody.length} out of {MAX_AD_BODY_SYMBOLS}{" "}
                  characters used)
                </span>
              </div>

              <Input
                style={{ height: "125px", backgroundColor: "white" }}
                sm={20}
                type="textarea"
                name="facebookCopy"
                id="facebookCopy"
                value={adData.adBody}
                onChange={e => this.handleChange("adBody", e)}
              />
            </FormGroup>

            {(this.campaignHasInstagramAds(campaignID) || adData.adImageIn) && (
              <FormGroup>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineheight: "17px",
                      letterSpacing: "0.04em",
                      color: "#6C757D",
                      margin: "20px 0",
                      padding: "0px"
                    }}
                  >
                    Instagram Copy
                  </span>

                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineheight: "17px",
                      letterSpacing: "0.04em",
                      color: "#6C757D",
                      margin: "20px 0",
                      padding: "0px"
                    }}
                  >
                    ({adData.adBodyIn.length} out of {MAX_AD_BODY_SYMBOLS}{" "}
                    characters used)
                  </span>
                </div>

                <Input
                  style={{ height: "125px", backgroundColor: "white" }}
                  sm={20}
                  type="textarea"
                  name="instagramCopy"
                  id="instagramCopy"
                  value={adData.adBodyIn}
                  onChange={e => this.handleChange("adBodyIn", e)}
                />
              </FormGroup>
            )}
            {(this.campaignHasLinkedInAds(campaignID) ||
              adData.adImageLinkedIn) && (
              <FormGroup>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineheight: "17px",
                      letterSpacing: "0.04em",
                      color: "#6C757D",
                      margin: "20px 0",
                      padding: "0px"
                    }}
                  >
                    LinkedIn Copy
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineheight: "17px",
                      letterSpacing: "0.04em",
                      color: "#6C757D",
                      margin: "20px 0",
                      padding: "0px"
                    }}
                  >
                    ({adData.adBodyLn.length} out of {MAX_AD_BODY_SYMBOLS}{" "}
                    characters used)
                  </span>
                </div>

                <Input
                  style={{ height: "125px", backgroundColor: "white" }}
                  sm={20}
                  type="textarea"
                  name="linkedInCopy"
                  id="linkedInCopy"
                  value={adData.adBodyLn}
                  onChange={e => this.handleChange("adBodyLn", e)}
                />
              </FormGroup>
            )}

            <h5 style={{ color: "black", fontWeight: "regular" }}>Ad Image</h5>

            <Label
              for="adImage"
              sm={8}
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                lineheight: "17px",
                letterSpacing: "0.04em",
                color: "#6C757D",
                margin: "20px 0px"
              }}
            >
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={this.state.addOverlay}
                onChange={() =>
                  this.setState({ addOverlay: !this.state.addOverlay })
                }
              />
              <span style={{ marginLeft: "10px" }} />
              Use overlay on top of uploaded images
            </Label>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap"
              }}
            >
              <ImageUploader
                uploadHandler={(acceptedFiles, platform) =>
                  this.handleImageUpload(acceptedFiles, platform)
                }
                generateHandler={platform => this.onRegenerateClick(platform)}
                canGenerateImage={
                  this.getCampaignData(campaignID).canGenerateImage
                }
                imageUrl={adData.adImageURL}
                text="Facebook Image"
                platform="facebook"
              />
              {this.campaignHasInstagramAds(campaignID) && (
                <ImageUploader
                  uploadHandler={(acceptedFiles, platform) =>
                    this.handleImageUpload(acceptedFiles, platform)
                  }
                  generateHandler={platform => this.onRegenerateClick(platform)}
                  canGenerateImage={
                    this.getCampaignData(campaignID).canGenerateImage
                  }
                  imageUrl={adData.adImageInUrl || adData.adImageURL}
                  text="Instagram Image"
                  platform="instagram"
                />
              )}

              {(this.campaignHasLinkedInAds(campaignID) ||
                adData.adImageLinkedIn) && (
                <ImageUploader
                  uploadHandler={(acceptedFiles, platform) =>
                    this.handleImageUpload(acceptedFiles, platform)
                  }
                  generateHandler={platform => this.onRegenerateClick(platform)}
                  canGenerateImage={
                    this.getCampaignData(campaignID).canGenerateImage
                  }
                  imageUrl={adData.adImageLinkedIn || adData.adImageURL}
                  text="LinkedIn Image"
                  platform="linkedIn"
                />
              )}
            </div>
          </Form>
          {this.state.errorMessage && (
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "15px 30px",
                color: "white",
                backgroundColor: "#F86969",
                borderRadius: "6px"
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C15.53 0 20 4.47 20 10C20 15.53 15.53 20 10 20C4.47 20 0 15.53 0 10C0 4.47 4.47 0 10 0ZM13.59 5L10 8.59L6.41 5L5 6.41L8.59 10L5 13.59L6.41 15L10 11.41L13.59 15L15 13.59L11.41 10L15 6.41L13.59 5Z"
                  fill="white"
                />
              </svg>

              <span style={{ marginLeft: "20px" }}>
                {this.state.errorMessage}
              </span>
            </div>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            {this.state.pendingServerReply && (
              <div
                style={{
                  width: "146px",
                  height: "38px",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px"
                }}
              >
                <span className="spinner" style={{ margin: "auto" }} />
              </div>
            )}
            {!this.state.pendingServerReply && (
              <button
                className={`btn btn-lg btn-secondary`}
                onClick={this.onUpdateClick}
                style={{
                  width: "146px",
                  height: "38px",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px"
                }}
              >
                Update Ad
              </button>
            )}
          </div>
        </div>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  adData: state.adData,
  user: state.user
});

const mapDispatchToProps = {
  setAdUpdateCompleted: notificationActions.setAdUpdateCompleted,
  setAdData: adDataActions.setAdData,
  resetAdData: adDataActions.resetAdData,
  unloadCampaigns: campaignActions.unloadCampaigns
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Edit));
