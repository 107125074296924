import { LOAD_ORG_SUCCESS } from "./action-types";

export const orgReducer = (state = null, { type, payload }) => {
  switch (type) {
    case LOAD_ORG_SUCCESS:
      return payload;
    default:
      return state;
  }
};
