import React, { Component } from "react";

export default class CustomAdRequest extends Component {
  render() {
    const { type, isFirst } = this.props;

    const introText =
      type === "jobs"
        ? "Custom ads is an efficient way of driving even more candidates to open positions with the power of social media."
        : "Employer branding ads is an efficient way of raising the awareness of you as an employer in social media - it builds trust and drives more candidates";

    const requestButtonText =
      type === "jobs"
        ? isFirst
          ? "Publish your first custom job ad"
          : "Publish a custom job ad"
        : isFirst
          ? "Publish your first branding ad"
          : "Publish a branding ad";

    const typeformURL =
      type === "jobs"
        ? "https://adwayads.typeform.com/to/o0Wgsq"
        : "https://adwayads.typeform.com/to/SJLcJw";

    return (
      <div className="cards-item p-4 bg-faded rounded">
        <div className="px-3 pt-3 py-3 text-center">
          <p className="text-muted px-5">{introText}</p>

          <a
            href={typeformURL}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-lg btn-secondary"
          >
            {requestButtonText}
            <ion-icon name="rocket" style={{ marginLeft: "14.4px" }} />
          </a>
        </div>
      </div>
    );
  }
}
