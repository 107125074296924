import { Record } from "immutable";

export const AdData = new Record({
  adBody: "",
  adBodyIn: "",
  adBodyLn: "",
  adCategory: "",
  adLocation: "",
  adImage: "",
  adImageLinkedIn: "",
  adImageIn: "",
  adImageURL: "",
  adLink: "",
  adLinkDesc: "",
  adLogoURL: "",
  adPageName: "",
  adTitle: "",
  date_end: "",
  id: "",
  employer: "",
  internal_id: ""
});
